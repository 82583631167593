<template>
  <div>
    <div v-if="activities.length !== 0">
      <p class="title">节点记录</p>
      <div class="contents">
        <div class="staff">
          <el-timeline :reverse="reverse">
            <el-timeline-item
              v-for="(activity, index) in activities"
              :key="index"
              :timestamp="activity.timestamp"
              :color="activity.color"
            >{{activity.content}}</el-timeline-item>
          </el-timeline>
        </div>
        <!-- <p class="time">03-16 15:28</p> -->
        <p class="skip" @click="dialogVisible = true">查看更多 ></p>
      </div>
    </div>
    <el-dialog title="节点记录" :visible.sync="dialogVisible" width="56%" :before-close="handleClose">
      <div class="box">
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column prop="nodeName" label="环节名称" width="180"></el-table-column>
          <el-table-column prop="realName" label="处理人" width="180">
            <template slot-scope="{row}">
                {{ row.realName || '已跳过该节点' }}
            </template>
          </el-table-column>
          <el-table-column prop="approveDate" label="完成时间" width="180"></el-table-column>
          <el-table-column prop="time" label="用时"></el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'App',
  props: ['recordList'],
  data() {
    return {
      tableData: [],
      dialogVisible: false,
      reverse: false,
      activities: []
    };
  },
  created() {},
  methods: {
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    }
  },
  watch: {
    recordList(value) {
      // console.log('99999', value);
      this.tableData = value;
      let num = '';
      if (this.tableData.length <= 3) {
        num = this.tableData.length;
      } else {
        num = 3;
      }
      for (var i = 0; i < num; i++) {
        var obj = {};
        obj.content = this.tableData[i].nodeName;
        obj.timestamp = this.tableData[i].time;
        this.activities.push(obj);
      }
      this.activities[0].color = '#3380FE';
    }
  }
};
</script>
<style lang="less" scoped>
.box {
  // width: 600px;
  margin: 0 auto;
  text-align: left;
}

.btn {
  border: 1px solid #ebeef5;
  padding: 5px 10px;
  background-color: #f5f7fa;
  outline: none;
}
.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(25, 31, 46, 1);
  line-height: 24px;
}
.contents {
  position: relative;
  width: 338px;
  height: 313px;
  padding: 20px 0 0 26px;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  margin: 12px 0 25px 0;
  .time {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
    line-height: 20px;
    position: absolute;
    right: 24px;
    top: 18px;
  }
  .staff {
    /deep/.el-timeline-item__content::before {
      content: '';
      position: absolute;
      left: -20px;
      display: inline-block;
      width: 0% !important;
      height: 0% !important;
      border: 10px solid #d0d5e2;
      border-color: #d0d5e2 transparent transparent transparent;
      transform: rotate(90deg);
    }
    /deep/.el-timeline-item__content {
      width: 60px;
      position: relative;
      color: #4b5162;
      background: rgba(208, 213, 226, 1);
      box-shadow: 1px 3px 5px 0px rgba(208, 213, 226, 0.7);
    }
    /deep/.el-timeline {
      li:nth-child(1)
        > .el-timeline-item__wrapper
        > .el-timeline-item__content {
        color: #fff;
        background: rgba(51, 128, 254, 1);
        box-shadow: 1px 3px 5px 0px rgba(51, 128, 254, 0.5);
      }
    }
    /deep/.el-timeline {
      li:nth-child(1)
        > .el-timeline-item__wrapper
        > .el-timeline-item__content::before {
        border: 10px solid #3380fe;
        border-color: #3380fe transparent transparent transparent;
      }
    }
  }
  .skip {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(75, 81, 98, 1);
    line-height: 24px;
    position: absolute;
    right: 23px;
    bottom: 10px;
  }
}
.el-timeline-item {
  padding-bottom: 65px;
}
</style>