<template>
  <div>
    <p class="title">流转节点</p>
    <div class="contents">
      <div class="staff">
        <p>
          <span>当前节点：</span>
          <span
            class="span"
          >{{currentNode == '' ? "拟稿" : (currentNode == null ? "办结" : currentNode)}} {{currentName}}</span>
        </p>
        <el-row  v-if="currentNode == '已办结' || currentNode == '办结'">
          <el-button type="primary" @click="circulateBtn">传阅</el-button>
        </el-row>
        <p v-if="currentNode !== '已办结' && currentNode !== '办结'">
          <span>下一节点：</span>
          <span v-if="nodeText === '相关处室意见'">{{nodeText}}</span>
          <span v-if="finish === 'FINISH' || nodeCode === 'FINISH'">办结</span>
          <el-select
            v-model="value"
            placeholder="请选择"
            @change="emitData"
            v-if="userNameDates !== [] && currentList.length === 0 && nodeText !== '相关处室意见' && finish !== 'FINISH'"
          >
            <el-option
              v-for="item in userNameDates"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
          <el-select
            v-model="value"
            placeholder="请选择"
            @change="emitData"
            v-if="currentList.length !== 0 && nodeText !== '相关处室意见' && nodeCode !== 'FINISH'"
          >
            <el-option v-for="item in currentList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </p>
        <p v-if="nodeCode !== 'FINISH' && currentNode !== '已办结' && !isFinish && type !== 'proposed'"><el-checkbox v-model="checked" @change="checkedBtn">点击选中跳过下一节点</el-checkbox></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: [
    'area',
    'code',
    'nodeName',
    'nodeCode',
    'approveSort',
    'currentApproveSort',
    'currentNode',
    'nodeData',
    'nodeText',
    'getDocumentId',
    'currentName'
  ],
  data() {
    return {
      level: 0, // 下一节点
      nextLevel: 0, // 
      value: '',
      nextValue: '',
      userNameDate: [],
      userNameDates: [],
      nextNameDate: [],
      nextNameDates: [],
      allData: '',
      nameDate: [],
      userAddList: [], // 自动审批列表-无审批人节点
      nextAddList: [], // 自动审批列表-无审批人节点
      TopId: '',
      list: [],
      finish: '',
      nextFinish: '',
      checked: false,
      currentList: [], // 当前节点选择人
      isFinish: false, // 下下一节点是否是已完结
      type: '',
      isIgnoreOpinion: 0 // 收文显示相关处室意见点击跳过下一节点传1
    };
  },
  created() {
    this.TopId = this.$route.query.id;
    this.type = this.$route.query.type;
    if (this.TopId == undefined) {
      this.selectNodeUser();
    }
  },
  methods: {
    // 传阅功能
    circulateBtn() {
      this.$emit('addSending');
    },
    // 获取下一节点
    async selectNodeUser() {
      const api = this.$fetchApi.getDocumentCodeByCon.api;
      const data = await this.$fetchData.fetchPost(
        { area: this.area, code: this.code, level: this.level },
        api + '?commonDocumentId=' + this.getDocumentId + '&isIgnoreOpinion=' + this.isIgnoreOpinion,
        'json'
      );
      // 下一节点相关处事单位
      if (data.code === '200') {
        this.userAddList = data.result.filter(
          item => item.userId === '' && item.isOpinionCode !== 1
        );
        console.log('无人审批节点');
        console.log(this.userAddList);
        this.userNameDate = data.result.filter(
          item => item.isOpinionCode === 1
        );
        if (this.userNameDate.length !== 0) {
          this.userNameDate.nodeName = this.userNameDate[0].nodeName;
          // this.$emit('addSending');
          this.level = this.userNameDate[0].level;
          this.userNameDate.nodeCode = this.userNameDate[0].nodeCode;
          this.emitData();
        }
        // 下一节点有审核人
        this.userNameDates = data.result.filter(item => item.userId !== '');
        if (this.userNameDates.length !== 0) {
          this.finish = this.userNameDates[0].nodeCode;
          this.userNameDate.nodeName = this.userNameDates[0].nodeName;
          this.userNameDate.nodeCode = this.userNameDates[0].nodeCode;
          this.level = this.userNameDates[0].level;
        }
        if (this.userNameDates.length !== 0 && this.finish !== 'FINISH') {
          let userNameList = [];
          let uidList = this.userNameDates[0].userId.split(',');
          this.userNameDates[0].userName.forEach((item, i) => {
            let _json = {};
            _json.name = this.userNameDates[0].nodeName + '-' + item;
            _json.id = uidList[i];
            userNameList.push(_json);
          });
          this.userNameDates = [];
          this.userNameDates = userNameList;
          this.value = this.userNameDates[0].id;
          if (this.level === 1) {
            data.result.forEach(item => { item.userId = '', item.userName = []; item.status = 1}); 
            this.nextNameDate = data.result;
          }
        }
        this.emitData();
      }
    },
     // 点击跳转下下一节点
    async checkedBtn(name) {
      if (name) {
        if (this.nodeCode === 'SW_A_XGCSYJ' || this.nodeCode === 'SW_B_XGCSYJ' ||
         this.nodeCode === 'SW_C_XGCSYJ' || this.nodeCode === 'SW_D_XGCSYJ' ) {
          this.isIgnoreOpinion = 1;
        } else {
          this.isIgnoreOpinion = 0;
        }
        this.level = this.approveSort;
        
        await this.selectNodeUser();
        console.log(this.nextNameDate)
        if (this.userNameDates.length !== 0) {
          if (this.userNameDates[0].nodeCode === 'FINISH') {
            // console.log(this.currentList);
            this.currentList = [];
          } else {
            this.currentList = this.userNameDates;
            // console.log(this.currentList);
          }
        }
      } else {
        this.level = this.currentApproveSort;
        await this.selectNodeUser();
        this.currentList = this.userNameDates;
      }
    },
    // 查询下一节点数据
    async getNextCode() {
      const api = this.$fetchApi.getDocumentCodeByCon.api;
      const data = await this.$fetchData.fetchPost(
        { area: this.area, code: this.code, level: this.approveSort },
        api + '?commonDocumentId=' + this.getDocumentId + '&isIgnoreOpinion=' + this.isIgnoreOpinion,
        'json'
      );
      console.log(data)
      if (data.result[0].nodeCode === 'FINISH') {
        this.isFinish = true;
      }
    },
    emitData() {
      this.$emit('child-event', {
        value: this.value,
        nodeCode: this.userNameDate.nodeCode || this.nodeCode,
        nodeName: this.userNameDate.nodeName || this.nodeName,
        userAddList: this.userAddList,
        nextNameDate: this.nextNameDate, // 跳转下下一节点，获取当前下一节点的数据
        level: this.level || this.approveSort,
        finish: this.finish
      });
    },
    setFinish() {
      return this.finish;
    }
  },
  watch: {
    nodeData: {
      handler(data) {
        console.log(data);
        if (this.nodeData.length !== 0) {
          this.currentList = this.nodeData;
          this.value = this.currentList[0].id;
          this.emitData();
        }
      },
      immediate: true
    },
    area(num) {
      this.getNextCode();
    }
  }
};
</script>
<style lang="less" scoped>
.contents {
  position: relative;
  width: 364px;
  // height: 200px;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  margin: 12px 0 25px 0;
  .staff {
    padding: 30px;
    color: #969eb4;
    .span {
      color: #3380fe;
    }
    p {
      margin-bottom: 10px;
    }
    .radio {
      display: inline-block;
    }
  }
}
</style>