<template>
    <div>
      <p class="title">快捷功能</p>
      <div class="contents">
        <div class="staff">
            <div @click="mimeograph"><img src="@/assets/images/niwen/print@2x.png" alt=""><p>打印</p></div>
            <!-- <div><img src="@/assets/images/niwen/download@2x.png" alt=""><p>下载</p></div> -->
            <div @click="addlist" v-if="!isId || presentForm.documentStatus == '1' || presentForm.documentStatus == '4'" ><img src="@/assets/images/niwen/draft@2x.png" alt=""><p>存草稿</p></div>
            <!-- <div><img src="@/assets/images/niwen/remove@2x.png" alt=""><p>删除</p></div> -->
        </div>
      </div>
        <el-button type="text" @click="open"></el-button>
    </div>
</template>
<script>
export default {
  props:['presentForm'],
  data() {
    return {
      isId: '' 
    }
  },
  methods: {
    addlist(){
      this.open()
    },
    mimeograph() {
      this.$emit("mimeograph");
    },
    open() {
      this.$confirm('是否保存草稿', '确认信息', {
        distinguishCancelAndClose: true,
        confirmButtonText: '保存',
        cancelButtonText: '放弃'
      }).then(() => {
        this.$message({
          type: 'info',
          message: '保存草稿成功'
        });
        this.$emit("addlist")
      }).catch(action => {
        this.$message({
          type: 'info',
          message: '放弃保存草稿'
        })
      });
    }
  },
  created() {
    this.isId = this.$route.query.id;
  }
};
</script>
<style lang="less" scoped>
.contents {
  position: relative;
  width: 364px;
  height: 137px;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  margin: 12px 0 25px 0;
  .staff {
   display: flex;
   justify-content:space-around;
   padding-top: 30px;
   img{
       width: 50px;
       height: 50px;
   }
   div{
       text-align: center;
   }
  }
}
</style>