<script>
/* COMPONENT DOCUMENT
 * author: liyulong
 * date: 2020/04/18
 * desc: 附件上传
 */

// import Vue from 'vue';
import config from '@/utils/config';

export default {
  name: 'accessory',
  props: {
    accessoryFile: { type: Array, default: () => [] }
  },

  data() {
    return {
      file_data: [],
      token: window.localStorage.getItem(config.baseToken)
    };
  },

  methods: {
    /** 上传文档 */
    uploadFile() {
      this.$refs.file_input.click();
    },
    async fileChange(e) {
      console.log(e)
      if (!e.target.value) { return; }
        const upload_types = ['docx', 'doc', 'xls', 'xlsx', 'ppt', 'pptx', 'pdf']; // 上传文件的类型
      const file = e.target.files[0];
      const suffix_name = file.name.replace(/.+\./, '').toLowerCase(); // 文件的后缀名

      // if (!upload_types.includes(suffix_name)) {
      //   this.$message({ type: 'error', message: '文件类型不符合!' });
      //   return;
      // }
      // if (file.size > 20 * 1024 * 1024) {
      //   this.$message({ type: 'error', message: '文件的大小已超过20M!' });
      //   return;
      // }
      // 上传到阿里云
      const formdata = new FormData();

      formdata.append('files', file);
      formdata.append('module', 'documentManagement');
      formdata.append('subModule', 'official');
      const api = '/api/file/open/encryptUpload-file';
      const data = await this.$fetchData.fetchPost(formdata, api, 'file');

      if (data.code === '200') {
        const api = '/api/file/decryptFile/' + data.result[0].id + '?access_token=' + this.token;
        this.file_data.push({
          id: data.result[0].id,
          filePath: config.baseUrl + api,
          name: data.result[0].name
        });
        this.$emit('accessoryData', this.file_data);
      } else {
        this.$message.error(data.message);
      }

      e.target.value = '';
    },
    /** 删除文件 */
    deleteFile(index) {
      this.$confirm('确认要删除当前附件吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({ type: 'success', message: '删除文件成功!' });
        this.file_data.splice(index, 1);
        this.$emit('accessoryData', this.file_data);
      }).catch(() => {
        this.$message({ type: 'info', message: '已取消删除' });          
      });
    },
    /** 查看文件 */
    viewFile(path) {
      if (!path) { return; }
      const suffix_name = path.replace(/.+\./, '').toLowerCase(); // 文件的后缀名

      if (['docx', 'doc', 'xls', 'xlsx', 'pptx'].includes(suffix_name)) {
        window.open('https://view.officeapps.live.com/op/view.aspx?src=' + path);
      } else {
        window.open(path);
      }
    }
  },

  watch: {
    accessoryFile: {
      handler() {
        this.file_data = JSON.parse(JSON.stringify(this.accessoryFile));
        this.file_data.forEach((item, index) => {
          if (item.id) {
            const api = '/api/file/decryptFile/' + item.id + '?access_token=' + this.token;

            this.$set(this.file_data[index], 'filePath', config.baseUrl + api);
          }
        });
        this.$emit('accessoryData', this.file_data);
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<template>
<div class="module_accessory">
  <div class="present-cont">
    <div class="tips">
      <img :src="require('@/assets/images/officialDocument/icon_annex.png')" alt="">
      <h3>请添加相关附件</h3>
      <!-- <p>※文件小于20M</p> -->
    </div>
    <input type="file" ref="file_input" @change="fileChange($event)" style="display: none;">
    <el-button class="btn" size="small" type="primary" @click="uploadFile">文件导入</el-button>
  </div>

  <div class="file_show" v-for="(item, index) in file_data" :key="index">
    <span>{{ index+1}} </span>
    <span>{{ item.name }}</span>
    <el-button type="text" style="color: #1440ff;" size="small"  @click="viewFile(item.filePath)">下载</el-button>
    <el-button type="text" style="color: #f56c6c;" size="small" @click="deleteFile(index)">删除</el-button>
  </div>
</div>
</template>

<style lang="less" scoped>
.module_accessory {
  position: relative;
  margin: 16px 0;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 4px 2px rgba(38,90,225,0.15);
  padding: 40px 20px;
  min-height: 600px;
  // padding-top: 78px;
  text-align: center;
  

  .present-cont {
    margin-bottom: 30px;
  }
  .file_show {
    text-align: left;
    span {
      margin-right: 10px;
      // cursor: pointer;
    }
  }
  .tips{
    margin-bottom: 20px;
    img {
      width: 94px;
    }
    h3 {
      color: #191F2E;
      font-size: 20px;
      line-height: 24px;
      margin: 16px auto 8px auto;
    }
    p {
      color: #969EB4;
      font-size: 14px;
    }
  }
  .btn {
    background: linear-gradient(0deg,rgba(51,128,254,1),rgba(20,64,255,1));
    border-radius: 6px;
    min-width: 140px;
  }
}

</style>
