<template>
  <div class="container">
    <nf-breadcrumb :breadList="breadList"></nf-breadcrumb>
    <div class="content">
      <div class="panel-left">
        <tab-nav class="nf-tabs" :tabsNav="tabsNav" @changeTabsNav="switchNav"></tab-nav>
        <!-- 发文呈批单 -->
        <presentation
          ref="group"
          v-show="currentTabsNav === 0 && code === 'POSTDOCUMENT'"
          :child_data="child_data"
          :nodeCode="nodeCode"
          :nodeName="nodeName"
          :presentForm="presentForm"
          :sendingList="sendingList"
          :automaticApprovalPerson="automaticApprovalPerson"
          :approveSort="approveSort"
          :textId="textId"
          :enclosureId="enclosureId"
          :area="area"
          :currentNode="currentNode"
          :currentApproveSort="currentApproveSort"
          :currentCode="currentCode"
          :currentHandle="currentHandle"
          :fatherFinish="fatherFinish"
          :fatherDetails="fatherDetails"
          :getDocumentId="getDocumentId"
          @childFn="parentFn"
        ></presentation>
        <!-- 合同呈批单 -->
        <Contract
          ref="group"
          v-if="currentTabsNav === 0 && code === 'CONTRACT'"
          :child_data="child_data"
          :nodeCode="nodeCode"
          :nodeName="nodeName"
          :presentForm="presentForm"
          :sendingList="sendingList"
          :automaticApprovalPerson="automaticApprovalPerson"
          :approveSort="approveSort"
          :textId="textId"
          :enclosureId="enclosureId"
          :area="area"
          :currentNode="currentNode"
          :currentApproveSort="currentApproveSort"
          :currentCode="currentCode"
          :currentHandle="currentHandle"
          :fatherFinish="fatherFinish"
          :fatherDetails="fatherDetails"
          :getDocumentId="getDocumentId"
          @childFn="parentFn"
        ></Contract>
        <!-- 公章呈批单 Cachet -->
        <Cachet
          ref="group"
          v-if="currentTabsNav === 0 && code === 'SEAL'"
          :child_data="child_data"
          :nodeCode="nodeCode"
          :nodeName="nodeName"
          :presentForm="presentForm"
          :sendingList="sendingList"
          :automaticApprovalPerson="automaticApprovalPerson"
          :approveSort="approveSort"
          :textId="textId"
          :enclosureId="enclosureId"
          :area="area"
          :currentNode="currentNode"
          :currentApproveSort="currentApproveSort"
          :currentCode="currentCode"
          :currentHandle="currentHandle"
          :fatherFinish="fatherFinish"
          :fatherDetails="fatherDetails"
          :getDocumentId="getDocumentId"
          :currentTabsNav="currentTabsNav"
          @childFn="parentFn"
        ></Cachet>
        <!-- 请示呈批单 -->
        <Instructions
          ref="group"
          v-if="currentTabsNav === 0 && code === 'REQUEST'"
          :child_data="child_data"
          :nodeCode="nodeCode"
          :nodeName="nodeName"
          :presentForm="presentForm"
          :sendingList="sendingList"
          :automaticApprovalPerson="automaticApprovalPerson"
          :approveSort="approveSort"
          :textId="textId"
          :enclosureId="enclosureId"
          :area="area"
          :currentNode="currentNode"
          :currentApproveSort="currentApproveSort"
          :currentCode="currentCode"
          :currentHandle="currentHandle"
          :fatherFinish="fatherFinish"
          :fatherDetails="fatherDetails"
          :getDocumentId="getDocumentId"
          @childFn="parentFn"
        ></Instructions>
        <!-- 收文呈批单 -->
        <Incoming
          ref="group"
          v-if="currentTabsNav === 0 && code === 'RECEIPT'"
          :child_data="child_data"
          :nodeCode="nodeCode"
          :nodeName="nodeName"
          :presentForm="presentForm"
          :sendingList="sendingList"
          :automaticApprovalPerson="automaticApprovalPerson"
          :approveSort="approveSort"
          :textId="textId"
          :enclosureId="enclosureId"
          :area="area"
          :currentNode="currentNode"
          :currentApproveSort="currentApproveSort"
          :currentCode="currentCode"
          :currentHandle="currentHandle"
          :fatherFinish="fatherFinish"
          :fatherDetails="fatherDetails"
          :getDocumentId="getDocumentId"
          @childFn="parentFn"
        ></Incoming>
        <!-- 正文 -->
        <main-document @fileData="mainFileChange" v-show="currentTabsNav === 1" :main-file="mainBodyFiles" />
        <!-- 附件 -->
        <accessory @accessoryData="accessoryChange" v-show="currentTabsNav === 2" :accessoryFile="accessoryFiles" />
        <el-button
          :disabled="isSubmit"
          type="primary"
          @click="submit"
          :style="{width:'760px',height:'60px',fontSize:'18px',background:'linear-gradient(to top,#3380fe,#969eb4)'}"
          v-if="(type != 'proposed' && currentTabsNav == 0) || (type == 'handle' && currentTabsNav == 0)"
        >
          <i class="el-icon-s-promotion"></i> 发送
        </el-button>
      </div>
      <div class="panel-right">
        <point
          :area="area"
          :code="code"
          :nodeName="nodeName"
          :nodeCode="nodeCode"
          :nodeData="nodeData"
          :nodeText="nodeText"
          :currentName="currentName"
          :approveSort="approveSort"
          :currentNode="currentNode"
          @child-event="getBData"
          v-on:addSending="addSending"
          :currentApproveSort="currentApproveSort"
          :getDocumentId="getDocumentId"
        />
        <addressee
          ref="address"
          :nodeName="nodeName"
          :nodeCode="nodeCode"
          :currentCode="currentCode"
          @children-event="getChildList"
          @children-approverId="getChildApproverId"
          v-on:addListSubmit="addListSubmit"
          v-on:circulateBtn="circulateBtn"
        />
        <shortcut v-on:mimeograph="mimeograph" v-on:addlist="addlist" :presentForm="presentForm"/>
        <recordPanel :recordList="recordListResps" />
      </div>
    </div>
  </div>
</template>
<script>
import nfBreadcrumb from '@/component/breadcrumb/breadcrumb';
import addressee from './purpose/addressee';
import shortcut from './purpose/shortcut';
import point from './purpose/point';
import recordPanel from './purpose/recordPanel';
import TabNav from './component/tab-nav';
// 呈批单
// import Presentation from './presentForm/presentation';
import Presentation from './presentForm/present/dispatch';
import Contract from './presentForm/present/contract';
import Cachet from './presentForm/present/cachet';
import Instructions from './presentForm/present/instructions';
import Incoming from './presentForm/present/incoming';
// 正文
import mainDocument from './presentForm/mainDocument';
// 附件
import accessory from './presentForm/accessory';

export default {
  components: {
    shortcut,
    point,
    addressee,
    nfBreadcrumb,
    TabNav,
    Presentation,
    Contract,
    Cachet,
    Instructions,
    Incoming,
    'main-document': mainDocument,
    accessory,
    recordPanel
  },

  data() {
    return {
      breadList: [
        {
          name: '业务协同',
          path: '/awaitDocumentText'
        },
        {
          name: '呈批单详情'
        }
      ],
      id: '',
      tabsNav: ['呈批单', '正文', '附件'],
      currentTabsNav: 0,
      area: '',
      code: '',
      currentName: '', // 当前节点名称
      child_data: '', // 下一节点处理人用户id
      nodeCode: '', // 下一节点code
      nodeName: '', // 下一节点名称
      nodeData: [], // 下一节点列表
      nodeText: [], // 下一节点名称
      sendingList: [], // 发文对象值
      presentForm: {}, // 回显数据详情
      recordListResps: [], // 节点记录数据
      automaticApprovalPerson: [], // 自动审批列表-无审批人节点
      currentData: [], // 点击跳转下一节点获取当前节点的数据
      approveSort: 0, // 下一流转节点排序
      textId: '', // 正文id
      enclosureId: [], // 附件id
      nameDate: [], // 下一节点有审核人数据
      type: '',
      accessoryFiles: [], // fujian
      mainBodyFiles: [], // 正文
      currentApproveSort: 0, // 当前流转节点排序
      currentCode: 'DRAFT_MAN', // 当前流转节点编号
      currentHandle: '', // 当前节点处理人用户id,必填,不可传无
      currentNode: '拟稿', // 当前节点名称
      fatherFinish: '',
      childApproverId: '',
      getDocumentId: '', // 详情获取呈批单id
      fatherDetails: {},
      isSubmit: false // 防止多次提交发送
    };
  },

  async created() {
    this.type = this.$route.query.type;
    this.id = this.$route.query.id;
    this.code = this.$route.query.code;
    if (this.id !== undefined) {
      await this.setDetailsList();
      this.area = this.presentForm.documentArea;
    } else {
      this.area = this.$route.query.area;
    }
  },
  watch: {
    approveSort: {
      handler(data) {
        if (this.currentData.length === 1) {
          this.currentData.forEach(item => { item.userId = '', item.userName = [], item.status = 1});
          this.automaticApprovalPerson = this.currentData;
          console.log(this.automaticApprovalPerson);
        }
      },
      immediate: true
    }
  },
  methods: {
    /** 正文文件 */
    mainFileChange(val) {
      this.textId = val.id || '';
    },
    /** 附件 */
    accessoryChange(val) {
      this.enclosureId = val.reduce((pre, cur) => {
        pre.push({
          fileId: cur.id,
          essayType: '2'
        });
        return pre;
      }, []);
    },
    addlist() {
      if (this.presentForm.documentStatus) {
        this.$refs.group.revise();
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.$router.push('/proposedText');
      } else {
        this.$refs.group.addList();
        this.$message({
          message: '保存成功',
          type: 'success'
        });
        this.$router.push('/proposedText');
      }
    },
    // 保存草稿
    mimeograph() {
      this.$refs.group.print();
    },
    switchNav(data) {
      this.currentTabsNav = data;
    },
    addListSubmit() {
      this.$refs.group.addListSubmit();
    },
    // 传阅发送
    circulateBtn() {
      this.$refs.group.circulateBtn();
    },
    // 发送按钮
    async submit() {
      this.addListSubmit();
    },
    parentFn(payload) {
      this.isSubmit = payload;
    },
    // 发文对象弹框
    addSending() {
      this.$refs.address.dialogVisibleBtn();
    },
    getBData(param) {
      console.log(param)
      this.child_data = param.value;
      this.nodeCode = param.nodeCode;
      this.nodeName = param.nodeName;
      this.approveSort = param.level;
      this.fatherFinish = param.finish;
      if (param.userAddList.length !== 0 && !this.automaticApprovalPerson) {
        this.automaticApprovalPerson = param.userAddList;
        // console.log(this.automaticApprovalPerson);
      }
      if (param.nextNameDate.length !== 0) {
        this.automaticApprovalPerson = param.nextNameDate;
        // console.log(this.automaticApprovalPerson);
      }
      if (this.nodeName === '相关处室意见') {
        this.nodeText = this.nodeName;
        this.child_data = '';
      } else {
        this.nodeText = '';
      }
    },
    // 获取发文对象值
    getChildList(param) {
      this.sendingList = param;
    },
    // 获取相关处事意见审批人
    getChildApproverId(param) {
      this.ChildApproverId = param;
      if (this.nodeName == '相关处室意见') {
        this.child_data = param;
      }
    },
    // 详情回显
    async setDetailsList() {
      const params = {
        documentType: this.code,
        id: this.id,
        isIgnoreOpinion: 0
      };
      const api = this.$fetchApi.findApplyDetails.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      this.presentForm = data.result.documentDetail;
      this.$set(this.presentForm, 'date1', [
        this.presentForm.useStartTime,
        this.presentForm.useEndTime
      ]);
      this.getDocumentId = data.result.documentDetail.id;
      // console.log('详情回显');
      // console.log(this.presentForm);
      this.fatherDetails = data.result; 
      this.currentApproveSort = data.result.currentCodeInfo.approveSort; // 当前流转节点排序
      this.currentCode = data.result.currentCodeInfo.circulationCode; // 当前流转节点编号
      this.currentHandle = data.result.currentCodeInfo.handleMemberId; // 当前节点处理人用户id,必填,不可传无
      this.currentName = data.result.currentCodeInfo.handleMemberName;  
      this.currentNode = data.result.currentCodeInfo.nodeName; // 当前节点名称
      this.enclosureId = data.result.documentDetail.accessoryFiles;
      const isOpinionList = data.result.nextCodeInfoList.filter(
        item => item.isOpinionCode === 1
      );
      if (isOpinionList.length === 1) {
        console.log('相关处事');
        // this.addSending();
        this.nodeText = isOpinionList[0].nodeName;
        this.approveSort = isOpinionList[0].level;
        this.nodeName = isOpinionList[0].nodeName;
        this.nodeCode = isOpinionList[0].nodeCode;
      } else {
        console.log('下一节点正常');
        this.nodeData = data.result.nextCodeInfoList.filter(
          item => item.userId !== ''
        );
        console.log(this.nodeData);
        if (this.nodeData.length !== 0) {
          this.nodeName = this.nodeData[0].nodeName;
          this.nodeCode = this.nodeData[0].nodeCode;
          this.approveSort = this.nodeData[0].level;
          this.currentData = data.result.nextCodeInfoList;
          this.automaticApprovalPerson = data.result.nextCodeInfoList.filter(
            item => item.userId === ''
          );
          console.log(this.automaticApprovalPerson)
          const userNameList = [];
          if (this.nodeCode == 'FINISH') {
            this.nodeText = 'FINISH';
          } else {
            const uidList = this.nodeData[0].userId.split(',');
            this.nodeData[0].userName.forEach((item, i) => {
              const _json = {};
              _json.name = this.nodeData[0].nodeName + '-' + item;
              _json.id = uidList[i];
              userNameList.push(_json);
            });
            this.nodeData = userNameList;
          }
        }
      }
      if (
        data.result.currentCodeInfo.circulationCode === 'FW_FWR' ||
        data.result.currentCodeInfo.circulationCode === 'QS_FWR'
      ) {
        console.log('发文人');
        if (this.type === 'proposed') {
          return;
        } else {
          this.addSending();
        }
      }
      this.recordListResps = data.result.recordListResps;
      this.accessoryFiles = data.result.accessoryFiles || [];
      this.mainBodyFiles = data.result.mainBodyFiles || [];
    }
  }
};
</script>
<style lang="less" scoped>
.content {
  max-width: 1200px;
  margin: 0 auto 60px auto;
  display: flex;
}

.panel-left {
  min-width: 810px;
  max-width: 760px;
  margin-right: 20px;
}
.panel-right {
  padding-top: 20px;
}
</style>