<template>
  <div class="present" ref="printDom">
    <div class="title">
      <p>海南省南繁管理局</p>
      <p>公章呈批单</p>
    </div>
    <div class="table">
      <div class="row title_row">
        <div class="metre metres">申请人</div>
        <div class="metre metres">申请部门</div>
        <div class="metre metres">申请日期</div>
        <div class="metre metres mear">公章类别</div>
      </div>
      <div class="row title_row">
        <div class="metre metres input scope">
          <el-input v-model="presentForm.draftUnit" maxlength="9" :disabled="isDrafter"></el-input>
        </div>
        <div class="metre metres input scope">
          <el-input v-model="presentForm.department" maxlength="9" :disabled="isDrafter"></el-input>
        </div>
        <div class="metre metres input scope">
          <el-date-picker v-model="presentForm.draftDate" type="date" value-format="yyyy-MM-dd" :disabled="isDrafter"></el-date-picker>
        </div>
        <div class="metre metres mear input scope">
          <el-input v-model="presentForm.sealType" maxlength="9" :disabled="isDrafter"></el-input>
        </div>
      </div>
      <div class="row title_row">
        <div class="metre metres">公章使用形式</div>
        <div class="metre metres mears">
          <el-radio v-model="presentForm.sealUseForm" label="1" :disabled="isDrafter">工作盖章使用</el-radio>
        </div>
        <div class="metre metres mears">
          <el-radio v-model="presentForm.sealUseForm" label="2" :disabled="isDrafter">临时借用</el-radio>
        </div>
        <div class="metre metres mear">
          <el-radio v-model="presentForm.sealUseForm" label="3" :disabled="isDrafter">例行工作使用</el-radio>
        </div>
      </div>
      <div class="row">
        <!-- <div class="metre altitude_height">公章使用事由</div>
        <div class="input official officials_height">
          <el-input class="center_class" type="textarea" maxlength="30" :rows="6" placeholder v-model="presentForm.documentTitle" :disabled="isDrafter"></el-input>
        </div> -->
        <div class="metre">公章使用事由</div>
        <div class="input official">
          <el-input v-if="!isDrafter" v-model="presentForm.documentTitle" type="textarea"
            :rows="2" maxlength="70" :disabled="isDrafter"></el-input>
          <div class="echo_box" v-else>
            <span class="echo_text">{{presentForm.documentTitle}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="metre metres">使/借用时间</div>
        <div class="input official text">
          <el-date-picker
            v-model="presentForm.date1"
            type="daterange"
            @change="dataPicher"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :disabled="isDrafter"
          ></el-date-picker>
          <p :style="{color:'#666'}">
            共计
            <span>{{days ? days : presentForm.days}}</span> 天
          </p>
        </div>
      </div>
      <div class="row">
        <!-- <div class="metre metres">盖章文件名称</div>
        <div class="input scope">
          <el-input v-model="presentForm.useDocumentName" maxlength="20" :disabled="isDrafter"></el-input>
        </div> -->
        <div class="metre metres">公章使用事由</div>
        <div class="input scope">
          <el-input v-if="!isDrafter" v-model="presentForm.useDocumentName" type="textarea"
            :rows="2" maxlength="50" :disabled="isDrafter"></el-input>
          <div class="echo_box" v-else>
            <span class="echo_text">{{presentForm.useDocumentName}}</span>
          </div>
        </div>
        <div class="metre copie">份数</div>
        <div class="input copies">
          <el-input type="number" oninput="if(value>999 || value<1)value=''" v-model="presentForm.useDocumentAmount" :disabled="isDrafter"></el-input>
        </div>
      </div>
      <div class="row">
        <!-- <div class="metre altitude_height">报送单位</div>
        <div class="input official officials_height">
          <el-input class="center_class" type="textarea" maxlength="30" :rows="6" placeholder v-model="presentForm.reportCompany" :disabled="isDrafter"></el-input>
        </div> -->
        <div class="metre">报送单位</div>
        <div class="input official">
          <el-input v-if="!isDrafter" v-model="presentForm.reportCompany" type="textarea"
            :rows="2" maxlength="70" :disabled="isDrafter"></el-input>
          <div class="echo_box" v-else>
            <span class="echo_text">{{presentForm.reportCompany}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="metre altitudes officials ling_height">处室意见</div>
        <div class="input official officials">
          <div class="textList" v-for="(item, i) in officeOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            :autosize="{ minRows: 5, maxRows: 100}"
            type="textarea"
            placeholder
            v-model="presentForm.officeOpinion"
            :disabled="isOfficeOpinion"
          ></el-input>
        </div>
      </div>
      <div class="row">
        <div class="metre altitudes officials ling_height">办公室主任意见</div>
        <div class="input official officials">
          <div class="textList" v-for="(item, i) in officeDirectorOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            :autosize="{ minRows: 5, maxRows: 100}"
            type="textarea"
            placeholder
            v-model="presentForm.officeDirectorOpinion"
            :disabled="isOfficeDirectorOpinion"
          ></el-input>
        </div>
      </div>
      <div class="row">
        <div class="metre altitudes officials ling_height">分管局领导意见</div>
        <div class="input official officials">
          <div class="textList" v-for="(item, i) in bureauLeaderOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            :autosize="{ minRows: 5, maxRows: 100}"
            type="textarea"
            placeholder
            v-model="presentForm.bureauLeaderOpinion"
            :disabled="isBureauLeaderOpinion"
          ></el-input>
        </div>
      </div>
      <div class="row">
        <div class="metre altitudes officials border-bottom ling_height">局长意见</div>
        <div class="input official officials border-bottom">
          <div class="textList" v-for="(item, i) in directorOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            :autosize="{ minRows: 5, maxRows: 100}"
            type="textarea"
            placeholder
            v-model="presentForm.directorOpinion"
            :disabled="isDirectorOpinion"
          ></el-input>
        </div>
      </div>
      <el-button type="text" @click="centerDialogVisible = true"></el-button>
      <el-dialog title="请选择审批结果" :visible.sync="centerDialogVisible">
        <el-form :model="form">
          <el-form-item label="审批结果" :label-width="formLabelWidth">
            <el-select v-model="form.region" placeholder="请选择">
              <el-option label="通过" value="1"></el-option>
              <el-option label="驳回" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitBtn">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import vue from 'vue';
import print from '../print';
vue.use(print);

export default {
  props: [
    'child_data',
    'nodeCode',
    'nodeName',
    'sendingList',
    'presentForm',
    'automaticApprovalPerson',
    'approveSort',
    'enclosureId',
    'textId',
    'area',
    'currentNode',
    'currentApproveSort',
    'currentCode',
    'currentHandle',
    'fatherFinish',
    'getDocumentId',
    'fatherDetails',
    'currentTabsNav'
  ],
  data() {
    return {
      userNameDates: [
        {name: '同意', id: '1'},
        {name: '驳回', id: '0'}
      ],
      options: [
        {
          value: '1',
          label: '普通'
        },
        {
          value: '0',
          label: '一般'
        }
      ],
      option: [
        {
          value: '0',
          label: '一般'
        },
        {
          value: '1',
          label: '急'
        },
        {
          value: '2',
          label: '紧急'
        }
      ],
      optio: [
        {
          value: '0',
          label: '公开'
        },
        {
          value: '1',
          label: '主动公开'
        },
        {
          value: '2',
          label: '依申请公开'
        },
        {
          value: '3',
          label: '不予公开'
        }
      ],
      fujuzhang: '',
      value: '',
      id: '', // 保存草稿获取公共呈批单id
      // useStartTime: '', // 公章使用开始日期
      // useEndTime: '', // 公章使用结束日期
      days: '',
      documentId: '',
      isDrafter: true, // 是否是拟稿人
      isOfficeOpinion: true, // 处室意见
      officeOpinionList: [], // 处室意见列表
      isOfficeDirectorOpinion: true, // 办公室主任意见
      officeDirectorOpinionList: [], // 办公室主任意见列表
      isBureauLeaderOpinion: true, // 分管局领导意见
      bureauLeaderOpinionList: [], // 分管局领导意见列表
      isDirectorOpinion: true, // 局长意见
      directorOpinionList: [], // 局长意见列表
      centerDialogVisible: false,
      handleStatus: 1, // 处理状态:0-驳回,1-同意
      handleOpinion: '', // 审批意见详情
      opinionType: '', // 意见类型
      isConfirm: false,
      form: {
        name: '',
        region: '1',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      formLabelWidth: '120px'
      // sendingFirst: '' // 如果为相关处事选择的id放在下一节点
    };
  },
  methods: {
    print() {
      const dom = this.$refs.printDom;
      this.$print(dom);   
    },
    // 弹框确定按钮
    submitBtn() {
      if (this.form.region == '1') {
        this.handleStatus = 1;
        if (this.currentCode === 'GZ_CSYJ' && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '通过';
        } else if (this.currentCode === 'GZ_JBGSYJ' && !this.presentForm.officeDirectorOpinion) {
          this.presentForm.officeDirectorOpinion = '通过';
        } else if (this.currentCode === 'GZ_FGJLDYJ' && !this.presentForm.bureauLeaderOpinion) {
          this.presentForm.bureauLeaderOpinion = '通过';
        } else if (this.currentCode === 'GZ_CWFJZ' && !this.presentForm.bureauLeaderOpinion) {
          this.presentForm.bureauLeaderOpinion = '通过';
        } else if (this.currentCode === 'GZ_JZ' && !this.presentForm.directorOpinion) {
          this.presentForm.directorOpinion = '通过';
        }
      } else {
        this.handleStatus = 0;
        if (this.currentCode === 'GZ_CSYJ' && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '驳回';
        } else if (this.currentCode === 'GZ_JBGSYJ' && !this.presentForm.officeDirectorOpinion) {
          this.presentForm.officeDirectorOpinion = '驳回';
        } else if (this.currentCode === 'GZ_FGJLDYJ' && !this.presentForm.bureauLeaderOpinion) {
          this.presentForm.bureauLeaderOpinion = '驳回';
        } else if (this.currentCode === 'GZ_CWFJZ' && !this.presentForm.bureauLeaderOpinion) {
          this.presentForm.bureauLeaderOpinion = '驳回';
        } else if (this.currentCode === 'GZ_JZ' && !this.presentForm.directorOpinion) {
          this.presentForm.directorOpinion = '驳回';
        }
      }
      this.centerDialogVisible = false
      this.addListSubmitLast();
    },
    // 添加草稿
    async addList() {
      const params = {
        // 呈批单添加草稿入参
        // 附件列表
        attachedFileList: this.enclosureId,
        // 呈批单公共数据
        commonDocumentApply: {
          bureauLeaderOpinion: '', // 分管局领导意见
          directorOpinion: '', // 局长意见
          documentArea: this.area, // 公文所属省/市
          documentStatus: this.presentForm.documentStatus, // 发送状态(1-草稿箱,2-已发送,3-已同意,4-已驳回)
          documentTitle: this.presentForm.documentTitle, // 公文标题
          documentType: this.value, // 呈批单类型
          draftDate: this.presentForm.draftDate, // 拟稿时间/申请日期
          draftPersonPhone: this.presentForm.draftPersonPhone, // 拟稿人及电话
          draftUnit: this.presentForm.draftUnit, // 拟稿单位/处室/人
          emergencyLevel: this.presentForm.emergencyLevel, // 紧急程度(0-一般,1-急,2-紧急)
          officeDirectorOpinion: '', // 办公室主任意见
          officeOpinion: '', // 相关处室意见
          sendTime: this.presentForm.sendTime // 发送时间
        },
        // 正文
        documentText: {
          applyId: '', // 呈批单id
          essayType: 1, // 文章类型 1正文 2附件
          fileId: this.textId // 文件id
        },
        // 公章呈批单
        officialSealApply: {
          department: this.presentForm.department, // 申请部门
          documentId: '', // 公文呈批单id
          reportCompany: this.presentForm.reportCompany, // 报送单位
          sealType: this.presentForm.sealType, // 公章类别
          sealUseCause: this.presentForm.documentTitle, // 公章使用事由
          sealUseForm: this.presentForm.sealUseForm, // 公章使用形式(1-工作盖章使用,2-临时借用,3-例行工作使用)
          useDocumentAmount: this.presentForm.useDocumentAmount, // 份数
          useDocumentName: this.presentForm.useDocumentName, // 盖章文件名称
          useEndTime: this.presentForm.useEndTime, // 公章使用结束日期
          useStartTime: this.presentForm.useStartTime // 公章使用开始日期
        }
      };
      const api = this.$fetchApi.insertDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      this.id = data.message;
    },
    // 发送
    async addListSubmit() {
      if (this.presentForm.draftUnit == undefined) {
        this.$message('请输入申请人');
        return;
      }
      if (this.presentForm.department == undefined) {
        this.$message('请输入申请部门');
        return;
      }
      if (this.presentForm.draftDate == undefined) {
        this.$message('请选择申请日期');
        return;
      }
      if (this.presentForm.sealType == undefined) {
        this.$message('请输入公章类别');
        return;
      }
      if (this.presentForm.sealUseForm == undefined) {
        this.$message('请选择公章使用形式');
        return;
      }
      if (this.presentForm.documentTitle == undefined) {
        this.$message('请输入公章使用事由');
        return;
      }
      if (this.presentForm.date1 == undefined) {
        this.$message('请选择使/借时间');
        return;
      }
      if (this.presentForm.useDocumentName == undefined) {
        this.$message('请输入盖章文件名称');
        return;
      }
      if (this.presentForm.useDocumentAmount == undefined) {
        this.$message('请输入份数');
        return;
      }
      if (this.presentForm.reportCompany == undefined) {
        this.$message('请输入报送单位');
        return;
      }
      if (
        this.currentNode !== '拟稿'
      ) {
        this.centerDialogVisible = true;
        // this.currentCode === 'GZ_CSYJ' ||
        // this.currentCode === 'GZ_JBGSYJ' ||
        // this.currentCode === 'GZ_FGJLDYJ' ||
        // this.currentCode === 'GZ_CWFJZ' ||
        // this.currentCode === 'GZ_JZ'
      } else {
        this.isConfirm = true;
        this.$emit('childFn', this.isConfirm);
        this.addListSubmitLast();
      }
    },
    // 发送接口 - 提取出来谁用谁调
    async addListSubmitLast() {
      let operationType = 1;
      if (this.currentNode == [] || this.currentNode == '拟稿') {
        operationType = 1;
      } else {
        operationType = 2;
      }
      if (this.presentForm.documentStatus === '1' || this.presentForm.documentStatus === '2') {
        await this.revise();
        console.log('修改草稿');
      } else if (this.presentForm.documentStatus === '3' || this.presentForm.documentStatus === '4') {
        return false;
      } else {
        await this.addList();
        console.log('新增保存草稿');
      }
      // console.log(this.automaticApprovalPerson);
      if (this.nodeCode === 'GZ_XGCSYJ') {
        this.sendingFirst = this.sendingList[0];
      }
      
      // 判断意见输入的内容 审批意见详情
      if (this.currentCode === 'GZ_CSYJ') {
        this.handleOpinion = this.presentForm.officeOpinion
        this.opinionType = 'officeOpinion' // 处室意见
      } else if (this.currentCode === 'GZ_JBGSYJ') {
        this.handleOpinion = this.presentForm.officeDirectorOpinion
        this.opinionType = 'officeDirectorOpinion' // 办公室主任意见
      } else if (this.currentCode === 'GZ_FGJLDYJ' || this.currentCode === 'GZ_CWFJZ') {
        this.handleOpinion = this.presentForm.bureauLeaderOpinion
        this.opinionType = 'bureauLeaderOpinion' // 分管理局领导意见
      } else if (this.currentCode === 'GZ_JZ') {
        this.handleOpinion = this.presentForm.directorOpinion
        this.opinionType = 'directorOpinion'  // 局长意见
      }

      // 两个之间相差为一自动审批列表-无审批人节点设置为空
      if (this.currentApproveSort + 1 === this.approveSort) {
        this.automaticApprovalPerson = [];
      } 
      const params = {
        automaticApprovalPerson: this.automaticApprovalPerson, // 自动审批列表-无审批人节点
        commonDocumentId: this.id, // 公共呈批单id
        documentArea: this.area, // 公文所属省/市
        documentType: this.value, // 呈批单类型
        handleStatus: this.handleStatus, // 处理状态:0-驳回,1-同意
        memberIdList: this.sendingList, // 发文对象id列表-仅在请示呈批单/发文呈批单/收文呈批单类型需要传参
        operationType: operationType, // 呈批单操作类型:1-拟稿人发送,2-拟文流转处理,3-传阅操作
        // 当前流转节点信息
        currentDocumentNodeReq: {
          approveSort: this.currentApproveSort, // 当前流转节点排序
          circulationCode: this.currentCode, // 当前流转节点编号
          handleMemberId: this.currentHandle, // 当前节点处理人用户id,必填,不可传无
          nodeName: this.currentNode // 当前流转节点名称
        },
        // 下一流转节点信息
        nextDocumentNodeReq: {
          approveSort: this.approveSort,
          circulationCode: this.nodeCode,
          handleMemberId: this.child_data,
          nodeName: this.nodeName
        },
        // 意见列表
        opinionListReq: [ 
          {
            handleOpinion: this.handleOpinion, // 审批意见详情
            opinionType: this.opinionType // 意见类型 流转节点编号
          }
        ]
      };
      const api = this.$fetchApi.sendDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      if (data.code == '200') {
        window.localStorage.removeItem('file');
        window.localStorage.removeItem('files');
        this.$message({
          message: '发送成功',
          type: 'success'
        });
        if (this.presentForm.documentStatus || this.presentForm.documentStatus == '1') {
          this.$router.push('/documentText');
        } else {
          this.$router.push('/proposedText');
        }
      }
    },
    // 修改草稿
    async revise() {
      let operationType = 1;
      if (this.presentForm.documentStatus == '4') {
        operationType = 2;
      } else {
        operationType = 1;
      }
      const params = {
        // 呈批单添加草稿入参
        // 附件列表
        attachedFileList: this.enclosureId,
        // 呈批单公共数据
        commonDocumentApply: {
          bureauLeaderOpinion: '', // 分管局领导意见
          directorOpinion: '', // 局长意见
          documentArea: this.area, // 公文所属省/市
          documentStatus: this.presentForm.documentStatus, // 发送状态(1-草稿箱,2-已发送,3-已同意,4-已驳回)
          documentTitle: this.presentForm.documentTitle, // 公文标题
          documentType: this.value, // 呈批单类型
          draftDate: this.presentForm.draftDate, // 拟稿时间/申请日期
          draftPersonPhone: this.presentForm.draftPersonPhone, // 拟稿人及电话
          draftUnit: this.presentForm.draftUnit, // 拟稿单位/处室/人
          emergencyLevel: this.presentForm.emergencyLevel, // 紧急程度(0-一般,1-急,2-紧急)
          officeDirectorOpinion: '', // 办公室主任意见
          officeOpinion: '', // 相关处室意见
          sendTime: this.presentForm.sendTime // 发送时间
        },
        commonDocumentId: this.presentForm.id, // 公共呈批单数据id
        documentId: this.presentForm.sealId, // 呈批单数据id
        documentType: this.value, // 呈批单类型
        operationType: operationType, // 操作类型,1-普通修改,2-驳回后编辑
        // 正文
        documentText: {
          applyId: '', // 呈批单id
          essayType: 1, // 文章类型 1正文 2附件
          fileId: this.textId // 文件id
        },
        // 公章呈批单
        officialSealApply: {
          department: this.presentForm.department, // 申请部门
          documentId: this.documentId, // 公文呈批单id
          reportCompany: this.presentForm.reportCompany, // 报送单位
          sealType: this.presentForm.sealType, // 公章类别
          sealUseCause: this.presentForm.documentTitle, // 公章使用事由
          sealUseForm: this.presentForm.sealUseForm, // 公章使用形式(1-工作盖章使用,2-临时借用,3-例行工作使用)
          useDocumentAmount: this.presentForm.useDocumentAmount, // 份数
          useDocumentName: this.presentForm.useDocumentName, // 盖章文件名称
          useEndTime: this.presentForm.useEndTime, // 公章使用结束日期
          useStartTime: this.presentForm.useStartTime // 公章使用开始日期
        }
      };
      const api = this.$fetchApi.updateDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      this.id = this.documentId;
    },
     // 发送接口 - 传阅发送
    async circulateBtn() {
      let operationType = 3;
      // 两个之间相差为一自动审批列表-无审批人节点设置为空
      if (this.currentApproveSort + 1 === this.approveSort) {
        this.automaticApprovalPerson = [];
      } 
      const params = {
        automaticApprovalPerson: this.automaticApprovalPerson, // 自动审批列表-无审批人节点
        commonDocumentId: this.getDocumentId, // 公共呈批单id
        documentArea: this.area, // 公文所属省/市
        documentType: this.value, // 呈批单类型
        handleStatus: this.handleStatus, // 处理状态:0-驳回,1-同意
        memberIdList: this.sendingList, // 发文对象id列表-仅在请示呈批单/发文呈批单/收文呈批单类型需要传参
        operationType: operationType, // 呈批单操作类型:1-拟稿人发送,2-拟文流转处理,3-传阅操作
        // 当前流转节点信息
        currentDocumentNodeReq: {
          approveSort: this.currentApproveSort, // 当前流转节点排序
          circulationCode: this.currentCode, // 当前流转节点编号
          handleMemberId: this.currentHandle, // 当前节点处理人用户id,必填,不可传无
          nodeName: this.currentNode // 当前流转节点名称
        },
        // 下一流转节点信息
        nextDocumentNodeReq: {
          approveSort: this.approveSort,
          circulationCode: this.nodeCode,
          handleMemberId: this.child_data,
          nodeName: this.nodeName
        }
      };
      const api = this.$fetchApi.sendDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      if (data.code == '200') {
        this.$message({
          message: '发送成功',
          type: 'success'
        });
        this.$router.push('/documentText');
      }
    },
    // 公章使用开始 - 结束日期
    dataPicher(data) {
      var start = new Date(this.presentForm.date1[0]);
      var end = new Date(this.presentForm.date1[1]);
      this.presentForm.useStartTime =
        start.getFullYear() +
        '-' +
        (start.getMonth() + 1 >= 10
          ? start.getMonth() + 1
          : '0' + (start.getMonth() + 1)) +
        '-' +
        (start.getDate() >= 10 ? start.getDate() : '0' + start.getDate());
      this.presentForm.useEndTime =
        end.getFullYear() +
        '-' +
        (end.getMonth() + 1 >= 10
          ? end.getMonth() + 1
          : '0' + (end.getMonth() + 1)) +
        '-' +
        (end.getDate() >= 10 ? end.getDate() : '0' + end.getDate());
      this.days = (end.getTime() - start.getTime()) / 86400000 + 1;  
      this.presentForm.days = this.days
      console.log(this.presentForm)
    }
  },
  created() {
    this.value = this.$route.query.code;
    this.documentId = this.$route.query.id;
    this.operationType = this.$route.query.type;
    console.log(this.presentForm)
    console.log(this.currentNode)
  },
  mounted() {
    // 意见列表回显
    if (this.fatherDetails.opinionList) {
      this.officeOpinionList = this.fatherDetails.opinionList.officeOpinion || [] // 处室意见列表
      this.officeDirectorOpinionList = this.fatherDetails.opinionList.officeDirectorOpinion || [] // 办公室主任意见列表
      this.bureauLeaderOpinionList = this.fatherDetails.opinionList.bureauLeaderOpinion || [] // 分管局领导意见列表
      this.directorOpinionList = this.fatherDetails.opinionList.directorOpinion || [] // 局长意见列表
    }
  },
  watch: {
    currentCode: {
      handler(val) {
        if (val === 'DRAFT_MAN') {
          this.isDrafter = false;
        } else {
          this.isDrafter = true;
        }
        if (val === 'GZ_CSYJ') {
          this.isOfficeOpinion = false;
        }
        if (val=== 'GZ_JBGSYJ') {
          this.isOfficeDirectorOpinion = false;
        }
        if (val === 'GZ_FGJLDYJ') {
          this.isBureauLeaderOpinion = false;
        }
        if (val === 'GZ_CWFJZ') {
          this.isBureauLeaderOpinion = false;
        }
        if (val === 'GZ_JZ') {
          this.isDirectorOpinion = false;
        }
      },
      immediate: true
    },
    presentForm() {
      console.log(333333)
      if (this.documentId) {
        let startTime = new Date(this.presentForm.useStartTime).getTime();
        let endTime = new Date(this.presentForm.useEndTime).getTime();
        this.presentForm.days = (endTime - startTime) / 86400000 + 1;
        this.days = (endTime - startTime) / 86400000 + 1;
        console.log(1111111)
      } else {
        this.dataPicher()
        console.log(222222)
      }
    },
    fatherDetails: {
      handler(val) {
        // 意见列表回显
        if (val.opinionList) {
          this.officeOpinionList = val.opinionList.officeOpinion || [] // 处室意见列表
          this.officeDirectorOpinionList = val.opinionList.officeDirectorOpinion || [] // 办公室主任意见列表
          this.bureauLeaderOpinionList = val.opinionList.bureauLeaderOpinion || [] // 分管局领导意见列表
          this.directorOpinionList = val.opinionList.directorOpinion || [] // 局长意见列表
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.title {
  margin: 10px 0;
  display: flex;
  font-size: 22px;
  color: red;
  justify-content: space-around;
}
.table {
  font-size: 16px;
  color: red;
  margin: 10px 0;
  width: 758px;
  min-height: 200px;
  border: 1px solid red;
  .rows {
    border-bottom: 1px solid red;
  }
  .row {
    display: flex;
    .metre {
      border-right: 1px solid red;
      border-bottom: 1px solid red;
      width: 225px;
      // height: 55px;
      text-align: center;
      line-height: 55px;
    }
    .metres {
      width: 226px;
    }
    .mear {
      border-right: none;
    }
    .mears {
      border-right: 1px solid #fff;
    }
    .altitude {
      width: 40px;
      padding: 0 20px;
      height: 110px;
      border-bottom: none;
    }

    .altitude_height {
      height: 60px;
      line-height: 60px;
    }
    .altitudeis {
      line-height: 300px;
    }
    .allboder {
      border-bottom: none;
      height: 300px;
    }
    .copie {
      line-height: 26px;
      padding: 0 5px;
      width: 25px;
    }
    .feed {
      width: 50px;
      height: 35px;
      line-height: 20px;
      padding: 10px 15px;
    }
    .key {
      width: 70px;
      line-height: 35px;
      border-top: 1px solid red;
      border-bottom: 1px solid red;
    }
    .keys {
      width: 150px;
      border-top: 1px solid red;
      border-bottom: 1px solid red;
      /deep/.el-input__inner {
        height: 54px;
        // border-bottom: 1px solid red;
        // border-radius: 0;
      }
    }
    .keyis {
      width: 324px;
      border-right: none;
    }
  }
  .input {
    border-bottom: 1px solid red;
    width: 130px;
    overflow: hidden;

    /deep/.el-input__inner {
      height: 55px;
      border: none;
      font-size: 16px;
    }
    /deep/.el-date-editor--date {
      width: 100%;
    }
    /deep/.el-input__prefix,
    /deep/.el-input__suffix-inner {
      display: none;
    }
    /deep/.el-input__inner {
      padding: 0 10px;
      text-align: center;
    }
    /deep/.el-textarea__inner {
      width: 99%;
      border: none;
      height: 99%;
      resize: none;
      text-align: center;
    }
  }
  .middle {
    width: 150px;
  }
  .finally {
    width: 235px;
    border-right: none;
  }
  .scope {
    width: 557px;
  }
  .copies {
    width: 85px;
    // border-right: none;
  }
  .official {
    width: 680px;
    // max-height: 300px;
    overflow: hidden;
    border-right: none;
  }
  .text {
    display: flex;
    justify-content: space-around;
    line-height: 50px;
    text-align: center;
    /deep/.el-range-separator {
      line-height: 55px;
    }
  }
  .offinput {
    border-bottom: none;
  }
  .officials {
    // height: 150px;
    /deep/.el-input,
    /deep/.el-input__inner {
      color: #757575;
      border: none;
      height: 100%;
      line-height: 100%;
      resize: none;
      width: 99%;
      margin-left: 1px;
      overflow:hidden
    }
  }

  .officials_height {
    height: 60px;
    border-bottom: 1px solid red;
  }
}
.textList {
  color: #757575;
  margin: 5px;
  font-size: 14px;
}

/deep/ .el-input.is-disabled .el-input__inner,
/deep/ .el-textarea.is-disabled .el-textarea__inner,
/deep/ .el-radio__input.is-disabled+span.el-radio__label,
/deep/ .el-range-editor.is-disabled input {
  color: #757575;
}

.center_class  {
  height: 60px;
  font-size: 16px;

  /deep/.el-textarea__inner {
    width: 99%;
    border: none;
    max-height: 100px;
    padding-top: 18px;
    resize: none;
    text-align: center;
  }
}

.border-bottom {
  border-bottom: none !important;
}

.ling_height {
  line-height: 110px !important;
}

.echo_box {
  display: table;
  background-color: #f5f7fa;
  height: 100%;
  width: 100%;
  color: #757575;
  font-size: 14px;
}

.echo_text {
  display: table-cell;
  vertical-align:middle;
  text-align:center;
}

</style>