<template>
  <div class="present" ref="printDom">
    <div class="title">
      <p>海南省南繁管理局</p>
      <p>合同呈批单</p>
    </div>
    <div class="table">
      <div class="row">
        <div class="metre">拟稿处室</div>
        <div class="input textAlign">
          <el-input
            v-if="!isDrafter"
            type="textarea"
            :rows="2"
            placeholder
            maxlength="14"
            v-model="presentForm.draftUnit"
            :disabled="isDrafter"
          ></el-input>
          <div v-else class="echo_box">
            <span class="echo_text">{{presentForm.draftUnit}}</span>
          </div>
        </div>
        <div class="metre">拟稿时间</div>
        <div class="input middle">
          <el-date-picker v-model="presentForm.draftDate" type="date" value-format="yyyy-MM-dd" :disabled="isDrafter"></el-date-picker>
        </div>
        <div class="metre">合同号</div>
        <div class="input finally textAlign">
          <el-input
            v-if="!isDrafter"
            type="textarea"
            :rows="2"
            placeholder
            maxlength="25"
            v-model="presentForm.contractNo"
            :disabled="isDrafter"
          ></el-input>
          <div v-else class="echo_box">
            <span class="echo_text">{{presentForm.contractNo}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="metre feed">密级及保密期</div>
        <div class="input">
          <el-select v-model="presentForm.confidentialityPeriod" placeholder disabled>
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="metre feed">拟稿人及电话</div>
        <div class="input middle echo_father textAlign">
          <el-input
            v-if="!isDrafter"
            type="textarea"
            :rows="2"
            placeholder
            maxlength="15"
            v-model="presentForm.draftPersonPhone"
            :disabled="isDrafter"
          ></el-input>
          <div v-else class="echo_box">
            <span class="echo_text">{{presentForm.draftPersonPhone}}</span>
          </div>
        </div>
        <div class="metre">签署时间</div>
        <div class="input finally">
          <el-date-picker v-model="presentForm.signTime" type="date" value-format="yyyy-MM-dd" :disabled="isDrafter"></el-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="metre">紧急程度</div>
        <div class="input">
          <el-select v-model="presentForm.emergencyLevel" placeholder :disabled="isDrafter">
            <el-option
              v-for="item in option"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
        <div class="metre feed">文核人及电话</div>
        <div class="input middle textAlign">
          <el-input
            v-if="!isDrafter"
            type="textarea"
            :rows="2"
            placeholder
            maxlength="15"
            v-model="presentForm.textCorePerson"
            :disabled="isDrafter"
          ></el-input>
          <div v-else class="echo_box">
            <span class="echo_text">{{presentForm.textCorePerson}}</span>
          </div>
        </div>
        <div class="metre feed">法核人及电话</div>
        <div class="input finally textAlign">
          <el-input
            v-if="!isDrafter"
            type="textarea"
            :rows="2"
            placeholder
            maxlength="25"
            v-model="presentForm.legalCorePerson"
            :disabled="isDrafter"
          ></el-input>
          <div v-else class="echo_box">
            <span class="echo_text">{{presentForm.legalCorePerson}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <!-- <div class="metre">签约单位</div>
        <div class="input scope">
          <el-input v-model="presentForm.contractCompany" maxlength="25" :disabled="isDrafter"></el-input>
        </div> -->
        <div class="metre">签约单位</div>
        <div class="input scope textAlign">
          <el-input v-if="!isDrafter" v-model="presentForm.contractCompany" type="textarea"
            :rows="2" maxlength="70" :disabled="isDrafter"></el-input>
          <div class="echo_box" v-else>
            <span class="echo_text">{{presentForm.contractCompany}}</span>
          </div>
        </div>  
        <div class="metre copie">份数</div>
        <div class="input copies">
          <el-input type="number" oninput="if(value>999 || value<1)value=''" v-model="presentForm.contractAmount" :disabled="isDrafter"></el-input>
        </div>
      </div>
      <div class="row">
        <!-- <div class="metre">合同标题</div>
        <div class="input official textAlign">
          <el-input v-model="presentForm.documentTitle" maxlength="35" :disabled="isDrafter"></el-input>
        </div> -->
        <div class="metre">合同标题</div>
        <div class="input official textAlign">
          <el-input v-if="!isDrafter" v-model="presentForm.documentTitle" type="textarea"
            :rows="2" maxlength="90" :disabled="isDrafter"></el-input>
          <div class="echo_box" v-else>
            <span class="echo_text">{{presentForm.documentTitle}}</span>
          </div>
        </div> 
      </div>
      <div class="row">
        <div class="metre">公开属性</div>
        <div class="input official">
          <el-select class="publicAttribute" v-model="presentForm.publicAttribute" placeholder :disabled="isDrafter">
            <el-option
              v-for="item in optio"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="row">
        <div class="metre altitudes officials">相关处室意见</div>
        <div class="input official officials">
          <div class="textList" v-for="(item, i) in officeOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            :autosize="{ minRows: 5, maxRows: 100}"
            type="textarea"
            placeholder
            v-model="presentForm.officeOpinion"
            :disabled="isOfficeOpinion"
          ></el-input>
        </div>
      </div>
       <div class="row">
        <div class="metre altitudes officials line_height">核稿意见</div>
        <div class="input official officials">
          <div class="textList" v-for="(item, i) in reviewCommentsList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input 
            :autosize="{ minRows: 5, maxRows: 100}"
            type="textarea"
            placeholder
            v-model="presentForm.reviewComments"
            :disabled="isReviewComments"
          ></el-input>
        </div>
      </div>
      <div class="row">
        <div class="metre altitudes officials">办公室主任意见</div>
        <div class="input official officials">
          <div class="textList" v-for="(item, i) in officeDirectorOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            :autosize="{ minRows: 5, maxRows: 100}"
            type="textarea"
            placeholder
            v-model="presentForm.officeDirectorOpinion"
            :disabled="isOfficeDirectorOpinion"
          ></el-input>
        </div>
      </div>
      <div class="row">
        <div class="metre altitudes officials">分管局领导意见</div>
        <div class="input official officials">
          <div class="textList" v-for="(item, i) in bureauLeaderOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            :autosize="{ minRows: 5, maxRows: 100}"
            type="textarea"
            placeholder
            v-model="presentForm.bureauLeaderOpinion"
            :disabled="isBureauLeaderOpinion"
          ></el-input>
        </div>
      </div>
      <div class="row">
        <div class="metre altitudes officials line_height border_bottom">局长意见</div>
        <div class="input official officials border_bottom">
          <div class="textList" v-for="(item, i) in directorOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            :autosize="{ minRows: 5, maxRows: 100}"
            type="textarea"
            v-model="presentForm.directorOpinion"
            :disabled="isDirectorOpinion"
          ></el-input>
        </div>
      </div>
      <el-button type="text" @click="centerDialogVisible = true"></el-button>
      <el-dialog title="请选择审批结果" :visible.sync="centerDialogVisible">
        <el-form :model="form">
          <el-form-item label="审批结果" :label-width="formLabelWidth">
            <el-select v-model="form.region" placeholder="请选择">
              <el-option label="通过" value="1"></el-option>
              <el-option label="驳回" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitBtn">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import vue from 'vue';
import print from '../print';
vue.use(print);

export default {
  props: [
    'child_data',
    'nodeCode',
    'nodeName',
    'sendingList',
    'presentForm',
    'automaticApprovalPerson',
    'approveSort',
    'enclosureId',
    'textId',
    'area',
    'currentNode',
    'currentApproveSort',
    'currentCode',
    'currentHandle',
    'fatherFinish',
    'fatherDetails',
    'getDocumentId'
  ],
  data() {
    return {
      input: '',
      value1: '',
      options: [
        {
          value: '1',
          label: '普通'
        },
        {
          value: '0',
          label: '一般'
        }
      ],
      option: [
        {
          value: '0',
          label: '一般'
        },
        {
          value: '1',
          label: '急'
        },
        {
          value: '2',
          label: '紧急'
        }
      ],
      optio: [
        {
          value: '0',
          label: '公开'
        },
        {
          value: '1',
          label: '主动公开'
        },
        {
          value: '2',
          label: '依申请公开'
        },
        {
          value: '3',
          label: '不予公开'
        }
      ],
      id: '', // 保存草稿获取公共呈批单id
      // type: 1, // 呈批单操作类型:1-拟稿人发送,2-拟文流转处理,3-传阅操作
      documentId: '', // 公文呈批单id
      operationType: '', //
      isReviewComments: true, // 核稿意见
      reviewCommentsList: [], // 核稿意见列表
      isDrafter: false, // 是否是拟稿人
      isOfficeOpinion: true, // 相关处室意见
      officeOpinionList: [], // 相关处室意见列表
      isOfficeDirectorOpinion: true, // 办公室主任意见
      officeDirectorOpinionList: [], // 办公室主任意见列表
      isBureauLeaderOpinion: true, // 分管局领导意见
      bureauLeaderOpinionList: [], // 分管局领导意见列表
      isDirectorOpinion: true, // 局长意见
      directorOpinionList: [], // 局长意见列表
      centerDialogVisible: false,
      handleStatus: 1, // 处理状态:0-驳回,1-同意
      handleOpinion: '', // 审批意见详情
      opinionType: '', // 意见类型
      isConfirm: false,
      form: {
        name: '',
        region: '1',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      formLabelWidth: '120px'
    };
  },
  methods: {
    print() {
      const dom = this.$refs.printDom;
      this.$print(dom);
    },
    // 弹框确定按钮
    submitBtn() {
      if (this.form.region == '1') {
        this.handleStatus = 1;
        if (this.currentCode === 'HT_XGCSYJ' && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '通过';
        } else if (this.currentCode === 'HT_JBGSYJ' && !this.presentForm.officeDirectorOpinion) {
          this.presentForm.officeDirectorOpinion = '通过';
        } else if (this.currentCode === 'HT_FGJLDYJ' && !this.presentForm.bureauLeaderOpinion) {
          this.presentForm.bureauLeaderOpinion = '通过';
        } else if (this.currentCode === 'HT_CWFJZ' && !this.presentForm.bureauLeaderOpinion) {
          this.presentForm.bureauLeaderOpinion = '通过';
        } else if (this.currentCode === 'HT_JZ' && !this.presentForm.directorOpinion) {
          this.presentForm.directorOpinion = '通过';
        }
      } else {
        this.handleStatus = 0;
        if (this.currentCode === 'HT_XGCSYJ' && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '驳回';
        } else if (this.currentCode === 'HT_JBGSYJ' && !this.presentForm.officeDirectorOpinion) {
          this.presentForm.officeDirectorOpinion = '驳回';
        } else if (this.currentCode === 'HT_FGJLDYJ' && !this.presentForm.bureauLeaderOpinion) {
          this.presentForm.bureauLeaderOpinion = '驳回';
        } else if (this.currentCode === 'HT_CWFJZ' && !this.presentForm.bureauLeaderOpinion) {
          this.presentForm.bureauLeaderOpinion = '驳回';
        } else if (this.currentCode === 'HT_JZ' && !this.presentForm.directorOpinion) {
          this.presentForm.directorOpinion = '驳回';
        }
      }
      this.centerDialogVisible = false;
      this.addListSubmitLast();
    },
    // 保存草稿
    async addList() {
      const params = {
        // 呈批单添加草稿入参
        // 附件列表
        attachedFileList: this.enclosureId,
        // 呈批单公共数据
        commonDocumentApply: {
          bureauLeaderOpinion: '', // 分管局领导意见
          directorOpinion: '', // 局长意见
          documentArea: this.area, // 公文所属省/市
          documentStatus: this.presentForm.documentStatus, // 发送状态(1-草稿箱,2-已发送,3-已同意,4-已驳回)
          documentTitle: this.presentForm.documentTitle, // 公文标题
          documentType: this.value, // 呈批单类型
          draftDate: this.presentForm.draftDate, // 拟稿时间/申请日期
          draftPersonPhone: this.presentForm.draftPersonPhone, // 拟稿人及电话
          draftUnit: this.presentForm.draftUnit, // 拟稿单位/处室/人
          emergencyLevel: this.presentForm.emergencyLevel, // 紧急程度(0-一般,1-急,2-紧急)
          officeDirectorOpinion: '', // 办公室主任意见
          officeOpinion: '', // 相关处室意见
          sendTime: this.presentForm.sendTime // 发送时间
        },
        // 正文
        documentText: {
          applyId: '', // 呈批单id
          essayType: 0, // 文章类型 1正文 2附件
          fileId: this.textId // 文件id
        },
        // 合同呈批单
        officialContractApply: {
          confidentialityPeriod: this.presentForm.confidentialityPeriod, // 密级及保密期(0一般,1普通)
          contractAmount: this.presentForm.contractAmount, // 份数
          contractCompany: this.presentForm.contractCompany, // 签约单位
          contractNo: this.presentForm.contractNo, // 合同号
          documentId: '', // 公文呈批单id
          legalCorePerson: this.presentForm.legalCorePerson, // 法核人及电话
          publicAttribute: this.presentForm.publicAttribute, // 公开属性(0-公开,1-主动公开,2-依申请公开,3-不予公开)
          reviewComments: '', // 核稿意见
          signTime: this.presentForm.signTime, // 签署时间
          textCorePerson: this.presentForm.textCorePerson // 文核人及电话
        }
      };
      const api = this.$fetchApi.insertDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      this.id = data.message;
    },
    // 发送前的判断
    async addListSubmit() {
      if (this.presentForm.draftUnit == undefined) {
        this.$message('请输入拟稿处室');
        return;
      }
      if (this.presentForm.draftDate == undefined) {
        this.$message('请选择拟稿时间');
        return;
      }
      // if (this.presentForm.contractNo == undefined) {
      //   this.$message('请输入合同号');
      //   return;
      // }
      // if (this.presentForm.confidentialityPeriod == undefined) {
      //   this.$message('请选择密级及保密期');
      //   return;
      // }
      if (this.presentForm.draftPersonPhone == undefined) {
        this.$message('请输入拟稿人及电话');
        return;
      }
      if (this.presentForm.signTime == undefined) {
        this.$message('请选择签署时间');
        return;
      }
      if (this.presentForm.emergencyLevel == undefined) {
        this.$message('请选择紧急程度');
        return;
      }
      if (this.presentForm.legalCorePerson == undefined) {
        this.$message('请输入法核人及电话');
        return;
      }
      if (this.presentForm.textCorePerson == undefined) {
        this.$message('请输入文核人及电话');
        return;
      }
      if (this.presentForm.contractCompany == undefined) {
        this.$message('请输入签约单位');
        return;
      }
      if (this.presentForm.contractAmount == undefined) {
        this.$message('请输入份数');
        return;
      }
      if (this.presentForm.documentTitle == undefined) {
        this.$message('请输入合同标题');
        return;
      }
      if (this.presentForm.publicAttribute == undefined) {
        this.$message('请选择公开属性');
        return;
      }
      if (this.child_data == '' && this.nodeCode === 'HT_XGCSYJ') {
        this.$message('请选择相关处室（单位）处理人');
        return;
      }
      if (this.currentCode === 'HT_HGR' && this.presentForm.reviewComments == '') {
        this.$message('请输入核稿意见');
        return;
      }
      if (this.currentNode !== '拟稿') {
        this.centerDialogVisible = true;
        // this.currentCode === 'HT_XGCSYJ' ||
        // this.currentCode === 'HT_JBGSYJ' ||
        // this.currentCode === 'HT_FGJLDYJ' ||
        // this.currentCode === 'HT_CWFJZ' ||
        // this.currentCode === 'HT_JZ'
      } else {
        this.isConfirm = true;
        this.$emit('childFn', this.isConfirm);
        this.addListSubmitLast();
      }
    },
    // 发送接口 - 提取出来谁用谁调
    async addListSubmitLast() {
      let operationType = 1;
      if (this.currentNode == [] || this.currentNode == '拟稿') {
        operationType = 1;
      } else {
        operationType = 2;
      }
      if (
        this.presentForm.documentStatus === '1' ||
        this.presentForm.documentStatus === '2'
      ) {
        await this.revise();
        console.log('修改草稿');
      } else if (
        this.presentForm.documentStatus === '3' ||
        this.presentForm.documentStatus === '4'
      ) {
        return false;
      } else {
        await this.addList();
        console.log('新增保存草稿');
      }

      // 判断意见输入的内容 审批意见详情
      if (this.currentCode === 'HT_XGCSYJ') {
        this.handleOpinion = this.presentForm.officeOpinion
        this.opinionType = 'officeOpinion' // 相关处室意见
      } else if (this.currentCode === 'HT_HGR') {
        this.handleOpinion = this.presentForm.reviewComments
        this.opinionType = 'reviewComments' // 核稿意见
      } else if (this.currentCode === 'HT_JBGSYJ') {
        this.handleOpinion = this.presentForm.officeDirectorOpinion
        this.opinionType = 'officeDirectorOpinion' // 办公室主任意见
      } else if (this.currentCode === 'HT_FGJLDYJ' || this.currentCode === 'HT_CWFJZ') {
        this.handleOpinion = this.presentForm.bureauLeaderOpinion
        this.opinionType = 'bureauLeaderOpinion' // 分管理局领导意见
      } else if (this.currentCode === 'HT_JZ') {
        this.handleOpinion = this.presentForm.directorOpinion
        this.opinionType = 'directorOpinion'  // 局长意见
      }

      // 两个之间相差为一自动审批列表-无审批人节点设置为空
      if (this.currentApproveSort + 1 === this.approveSort) {
        this.automaticApprovalPerson = [];
      } 
      const params = {
        automaticApprovalPerson: this.automaticApprovalPerson, // 自动审批列表-无审批人节点
        commonDocumentId: this.id, // 公共呈批单id
        documentArea: this.area, // 公文所属省/市
        documentType: this.value, // 呈批单类型
        handleStatus: this.handleStatus, // 处理状态:0-驳回,1-同意
        memberIdList: this.sendingList, // 发文对象id列表-仅在请示呈批单/发文呈批单/收文呈批单类型需要传参
        operationType: operationType, // 呈批单操作类型:1-拟稿人发送,2-拟文流转处理,3-传阅操作
        // 当前流转节点信息
        currentDocumentNodeReq: {
          approveSort: this.currentApproveSort, // 当前流转节点排序
          circulationCode: this.currentCode, // 当前流转节点编号
          handleMemberId: this.currentHandle, // 当前节点处理人用户id,必填,不可传无
          nodeName: this.currentNode // 当前流转节点名称
        },
        // 下一流转节点信息
        nextDocumentNodeReq: {
          approveSort: this.approveSort,
          circulationCode: this.nodeCode,
          handleMemberId: this.child_data,
          nodeName: this.nodeName
        },
        // 意见列表
        opinionListReq: [ 
          {
            handleOpinion: this.handleOpinion, // 审批意见详情
            opinionType: this.opinionType // 意见类型 流转节点编号
          }
        ]
      };
      const api = this.$fetchApi.sendDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      if (data.code == '200') {
        window.localStorage.removeItem('file');
        window.localStorage.removeItem('files');
        this.$message({
          message: '发送成功',
          type: 'success'
        });
        if (this.presentForm.documentStatus || this.presentForm.documentStatus == '1') {
          this.$router.push('/documentText');
        } else {
          this.$router.push('/proposedText');
        }
      }
    },
    // 修改草稿
    async revise() {
      let operationType = 1;
      if (this.presentForm.documentStatus == '4') {
        operationType = 2;
      } else {
        operationType = 1;
      }
      const params = {
        // 呈批单添加草稿入参
        // 附件列表
        attachedFileList: this.enclosureId,
        // 呈批单公共数据
        commonDocumentApply: {
          bureauLeaderOpinion: '', // 分管局领导意见
          directorOpinion: '', // 局长意见
          documentArea: this.area, // 公文所属省/市
          documentStatus: this.presentForm.documentStatus, // 发送状态(1-草稿箱,2-已发送,3-已同意,4-已驳回)
          documentTitle: this.presentForm.documentTitle, // 公文标题
          documentType: this.value, // 呈批单类型
          draftDate: this.presentForm.draftDate, // 拟稿时间/申请日期
          draftPersonPhone: this.presentForm.draftPersonPhone, // 拟稿人及电话
          draftUnit: this.presentForm.draftUnit, // 拟稿单位/处室/人
          emergencyLevel: this.presentForm.emergencyLevel, // 紧急程度(0-一般,1-急,2-紧急)
          officeDirectorOpinion: '', // 办公室主任意见
          officeOpinion: '', // 相关处室意见
          sendTime: this.presentForm.sendTime // 发送时间
        },
        commonDocumentId: this.presentForm.id, // 公共呈批单数据id
        documentId: this.presentForm.contractId, // 呈批单数据id
        documentType: this.value, // 呈批单类型
        operationType: operationType, // 操作类型,1-普通修改,2-驳回后编辑
        // 正文
        documentText: {
          applyId: '', // 呈批单id
          essayType: 0, // 文章类型 1正文 2附件
          fileId: this.textId // 文件id
        },
        // 合同呈批单
        officialContractApply: {
          confidentialityPeriod: this.presentForm.confidentialityPeriod, // 密级及保密期(0一般,1普通)
          contractAmount: this.presentForm.contractAmount, // 份数
          contractCompany: this.presentForm.contractCompany, // 签约单位
          contractNo: this.presentForm.contractNo, // 合同号
          documentId: this.documentId, // 公文呈批单id
          legalCorePerson: this.presentForm.legalCorePerson, // 法核人及电话
          publicAttribute: this.presentForm.publicAttribute, // 公开属性(0-公开,1-主动公开,2-依申请公开,3-不予公开)
          reviewComments: '', // 核稿意见
          signTime: this.presentForm.signTime, // 签署时间
          textCorePerson: this.presentForm.textCorePerson // 文核人及电话
        }
      };
      const api = this.$fetchApi.updateDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      this.id = this.documentId;
    },
     // 发送接口 - 传阅发送
    async circulateBtn() {
      let operationType = 3;
      // 两个之间相差为一自动审批列表-无审批人节点设置为空
      if (this.currentApproveSort + 1 === this.approveSort) {
        this.automaticApprovalPerson = [];
      } 
      const params = {
        automaticApprovalPerson: this.automaticApprovalPerson, // 自动审批列表-无审批人节点
        commonDocumentId: this.getDocumentId, // 公共呈批单id
        documentArea: this.area, // 公文所属省/市
        documentType: this.value, // 呈批单类型
        handleStatus: this.handleStatus, // 处理状态:0-驳回,1-同意
        memberIdList: this.sendingList, // 发文对象id列表-仅在请示呈批单/发文呈批单/收文呈批单类型需要传参
        operationType: operationType, // 呈批单操作类型:1-拟稿人发送,2-拟文流转处理,3-传阅操作
        // 当前流转节点信息
        currentDocumentNodeReq: {
          approveSort: this.currentApproveSort, // 当前流转节点排序
          circulationCode: this.currentCode, // 当前流转节点编号
          handleMemberId: this.currentHandle, // 当前节点处理人用户id,必填,不可传无
          nodeName: this.currentNode // 当前流转节点名称
        },
        // 下一流转节点信息
        nextDocumentNodeReq: {
          approveSort: this.approveSort,
          circulationCode: this.nodeCode,
          handleMemberId: this.child_data,
          nodeName: this.nodeName
        }
      };
      const api = this.$fetchApi.sendDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      if (data.code == '200') {
        this.$message({
          message: '发送成功',
          type: 'success'
        });
        this.$router.push('/documentText');
      }
    }
  },
  created() {
    this.value = this.$route.query.code;
    this.documentId = this.$route.query.id;
    this.operationType = this.$route.query.type;
  },
  mounted() {
    // 意见列表回显
    if (this.fatherDetails.opinionList) {
      this.reviewCommentsList = this.fatherDetails.opinionList.reviewComments || [] // 核稿意见
      this.officeOpinionList = this.fatherDetails.opinionList.officeOpinion || [] // 相关处室意见列表
      this.officeDirectorOpinionList = this.fatherDetails.opinionList.officeDirectorOpinion || [] // 办公室主任意见列表
      this.bureauLeaderOpinionList = this.fatherDetails.opinionList.bureauLeaderOpinion || [] // 分管局领导意见列表
      this.directorOpinionList = this.fatherDetails.opinionList.directorOpinion || [] // 局长意见列表
    }
  },
  watch: {
    currentCode: {
      handler(val) {
        if (val === 'DRAFT_MAN') {
          this.isDrafter = false;
        } else {
          this.isDrafter = true;
        }
        if (val === 'HT_XGCSYJ') {
        this.isOfficeOpinion = false;
        }
        if (val === 'HT_HGR') {
          this.isReviewComments = false;
        }
        if (val === 'HT_JBGSYJ') {
          this.isOfficeDirectorOpinion = false;
        }
        if (val === 'HT_FGJLDYJ') {
          this.isBureauLeaderOpinion = false;
        }
        if (val === 'HT_CWFJZ') {
          this.isBureauLeaderOpinion = false;
        }
        if (val === 'HT_JZ') {
          this.isDirectorOpinion = false;
        }
      },
      immediate: true
    },
    fatherDetails: {
      handler(val) {
        // 意见列表回显
        if (val.opinionList) {
          this.reviewCommentsList = val.opinionList.reviewComments || [] // 核稿意见
          this.officeOpinionList = val.opinionList.officeOpinion || [] // 相关处室意见列表
          this.officeDirectorOpinionList = val.opinionList.officeDirectorOpinion || [] // 办公室主任意见列表
          this.bureauLeaderOpinionList = val.opinionList.bureauLeaderOpinion || [] // 分管局领导意见列表
          this.directorOpinionList = val.opinionList.directorOpinion || [] // 局长意见列表
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.title {
  margin: 10px 0;
  display: flex;
  font-size: 22px;
  color: red;
  justify-content: space-around;
}
.table {
  font-size: 16px;
  color: red;
  margin: 10px 0;
  width: 758px;
  min-height: 200px;
  border: 1px solid red;
  .rows {
    border-bottom: 1px solid red;
  }
  .row {
    display: flex;
    .metre {
      border-right: 1px solid red;
      border-bottom: 1px solid red;
      width: 80px;
      // height: 55px;
      text-align: center;
      line-height: 55px;
    }
    .altitude {
      width: 40px;
      padding: 0 20px;
      height: 150px;
      border-bottom: none;
    }
    .padding_top {
      padding-top: 40px;
    }
    .altitudeis {
      line-height: 200px;
    }
    .allboder {
      border-bottom: none;
      height: 200px;
    }
    .copie {
      line-height: 26px;
      padding: 0 5px;
      width: 25px;
    }
    .feed {
      width: 50px;
      height: 35px;
      line-height: 20px;
      padding: 10px 15px;
    }
    .key {
      width: 70px;
      line-height: 35px;
      border-top: 1px solid red;
      border-bottom: 1px solid red;
    }
    .keys {
      width: 150px;
      border-top: 1px solid red;
      border-bottom: 1px solid red;
      /deep/.el-input__inner {
        height: 54px;
        // border-bottom: 1px solid red;
        // border-radius: 0;
      }
    }
    .keyis {
      width: 324px;
      border-right: none;
    }
  }
  .input {
    border-right: 1px solid red;
    border-bottom: 1px solid red;
    width: 130px;
    overflow: hidden;

    /deep/.el-input__inner {
      height: 55px;
      border: none;
      font-size: 16px;
      width: 99%;
    }
    /deep/.el-date-editor--date {
      width: 100%;
    }
    /deep/.el-input__prefix,
    /deep/.el-input__suffix-inner {
      display: none;
    }
    /deep/.el-input__inner {
      padding: 0 15px;
      height: 54px;
      text-align: center;
    }
    /deep/.el-textarea__inner {
      border: none;
      height: 100%;
      resize: none;
      width: 99%;
      padding: 5px;
      // text-align: center;
    }

    // /deep/.is-disabled {
    //   height: 10px;
    // }
  }
  .middle {
    width: 150px;
  }
  .finally {
    width: 235px;
    border-right: none;
  }
  .scope {
    width: 557px;
  }
  .copies {
    width: 85px;
    border-right: none;
  }
  .official {
    width: 679px;
    // max-height: 150px;
    overflow: hidden;
    border-right: none;
    border-bottom: 1px solid red;
  }

  .offinput {
    border-bottom: none;
  }
  .officials {
    // height: 150px;
    /deep/.el-input,
    /deep/.el-input__inner {
      color: #757575;
      border: none;
      height: 100%;
      line-height: 100%;
      resize: none;
      width: 99%;
      margin-left: 1px;
      overflow:hidden
    }
  }
}
.textList {
  color: #757575;
  margin: 5px;
  font-size: 14px;
}
.officialHeight {
  max-height: 200px !important;
}

/deep/ .el-textarea.is-disabled .el-textarea__inner,
/deep/ .el-input.is-disabled .el-input__inner {
  color: #757575;
}

.publicAttribute {
  width: 100%;
}

.echo_box {
  display: table;
  background-color: #f5f7fa;
  height: 100%;
  width: 100%;
  color: #757575;
  font-size: 14px;
}

.echo_text {
  display: table-cell;
  vertical-align:middle;
  text-align:center;
}

.border_bottom {
  border-bottom: none !important;
}

.line_height {
  line-height: 110px !important;
}

.textAlign /deep/.el-textarea__inner {
  text-align: center;
}
</style>