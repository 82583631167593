<script>
/* COMPONENT DOCUMENT
 * author: liyulong
 * date: 2020/04/18
 * desc: 正文
 */

// import Vue from 'vue';
import config from '@/utils/config';

export default {
  name: 'main-document',
  props: {
    mainFile: { type: Array, default: () => [] }
  },
  data() {
    return {
      kcCloudUrl: config.kcCloudUrl,
      accountUseerId: '',
      file_data: {},
      fileInfo: {},
      userType: '',
      form: {
        name: '',
        region: '1',
      },
      isAddDialog: false, // 新建文件弹框选择
      token: window.localStorage.getItem(config.baseToken),
      kcCloudToken: window.localStorage.getItem(config.kcCloudToken)
    };
  },

  created() {
    this.userType = this.$route.query.type;
    // console.log('>>>>####', this.userType);
    // 登录用户id
    this.accountUseerId = localStorage.getItem('accountUseerId');
  },

  methods: {
    /** 上传文档 */
    uploadFile() {
      this.$refs.file_input.click();
    },
    async fileChange(e) {
      if (!e.target.value) { return; }
      const upload_types = ['docx', 'doc', 'xls', 'xlsx', 'pdf']; // 上传文件的类型
      const file = e.target.files[0];
      const suffix_name = file.name.replace(/.+\./, '').toLowerCase(); // 文件的后缀名

      if (!upload_types.includes(suffix_name)) {
        this.$message({ type: 'error', message: '文件类型不符合!' });
        return;
      }
      // if (file.size > 20 * 1024 * 1024) {
      //   this.$message({ type: 'error', message: '文件的大小已超过20M!' });
      //   return;
      // }
      // 上传到阿里云
      const formdata = new FormData();

      formdata.append('files', file);
      formdata.append('module', 'documentManagement');
      formdata.append('subModule', 'official');
      const api = '/api/file/open/encryptUpload-file';
      const data = await this.$fetchData.fetchPost(formdata, api, 'file');

      if (data.code === '200') {
        this.fileInfo = data.result[0];
        this.fileInfo.createdDate = encodeURIComponent(this.fileInfo.createdDate);
        const api = '/api/file/decryptFile/' + data.result[0].id + '?access_token=' + this.token;
        this.file_data = {
          id: data.result[0].id,
          filePath: config.baseUrl + api
        };
        console.log(this.file_data)
        this.$emit('fileData', this.file_data);
      } else {
        this.$message.error(data.message);
      }

      e.target.value = '';
    },
    /** 下载 */
    downLoadFile() {
      const api = '/api/file/decryptFile/' + this.file_data.id + '?access_token=' + this.token;
      const a = document.createElement('a');

      a.href = config.baseUrl + api;
      a.click();
    },
    /** 删除 */
    deleteFile() {
      this.$confirm('确认要删除当前正文吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({ type: 'success', message: '删除文件成功!' });
        this.file_data = {};
      this.$emit('fileData', {});
      }).catch(() => {
        this.$message({ type: 'info', message: '已取消删除' });          
      });
    },
    // 新增文件
    addFile() {
      this.isAddDialog = true
    },
    // 新增文件 确定按钮
    async submitBtn() {
      const params = {
        name: this.form.name,
        type: 'XLSX'
      }
      if (this.form.region == '1') {
        params.type = 'DOCX'
      } else {
        params.type = 'XLSX'
      }
      const api = '/api/official/app/handlerEssay/newFile' + '?access_token=' + this.token;
      const data = await this.$fetchData.fetchGet(params, api, '');
      if (data.code === '200') {
        this.isAddDialog = false
        this.file_data = {
          id: data.result.id,
          filePath: config.baseUrl + '/api/file/decryptFile/' + data.result.id + '?access_token=' + this.token
        };
        this.$emit('fileData', this.file_data);
        this.fileInfo.filePath = data.result.filePath;
        this.fileInfo.id = data.result.id;
        this.fileInfo.fileSize = data.result.fileSize;
      } else {
        this.$message.error(data.message);
      }
    }
  },
  watch: {
    mainFile: {
      handler() {
        this.file_data = this.mainFile[0]  && this.mainFile[0] != 'undefined' ? JSON.parse(JSON.stringify(this.mainFile[0])) : {};
        this.fileInfo = this.mainFile[0] && this.mainFile[0] != 'undefined' ? JSON.parse(JSON.stringify(this.mainFile[0])) : {};
        this.fileInfo.createdDate = encodeURIComponent(this.fileInfo.createdDate);
        if (this.file_data.id) {
          const api = '/api/file/decryptFile/' + this.file_data.id + '?access_token=' + this.token;

          this.$set(this.file_data, 'filePath', config.baseUrl + api);
        }

        this.$emit('fileData', this.file_data);
      },
      immediate: true,
      deep: true
    }
  }
};
</script>

<template>
  <div class="module_main_document">
    
    <div class="present-cont" v-if="!file_data.filePath">
      <div class="tips">
        <img :src="require('@/assets/images/officialDocument/icon_download.png')" alt="">
        <h3>请导入相关文件</h3>
        <p>※支持word格式</p>
      </div>
      <input type="file" ref="file_input" @change="fileChange($event)" style="display: none;">
      <el-button class="btn" size="small" type="primary" @click="uploadFile">导入</el-button><br/>
      <el-button class="btn addFile" size="small" type="primary" @click="addFile">新增文件</el-button>
      
    </div>
    
    <div v-else class="view-file">
      <el-button class="delete" type="danger" size="small" @click="deleteFile" v-if="userType !== 'proposed' || userType == 'handle'">删除</el-button>
      <el-button class="delete" type="primary" size="small" @click="downLoadFile" v-if="userType !== 'proposed' || userType == 'handle'">下载</el-button>
      <iframe ref="iframe" id="iframe" :src="`${kcCloudUrl}/kccloud-h5/#/viewWpsFile?token=${token}&appType=nanfan&fileUrl=${fileInfo.filePath}&fileId=${fileInfo.id}&permission=${this.userType === 'proposed' ? 'read' : 'write'}&fileSize=${fileInfo.fileSize}&downloadSrc=${file_data.filePath}&resetToken=1&userId=${accountUseerId}&fileTime=${fileInfo.createdDate}`" :key="file_data.id" frameborder="0"></iframe>
    </div>

    <el-dialog title="请选择新建文件" :visible.sync="isAddDialog">
      <el-form :model="form">
        <el-form-item label="文件名称" label-width="120px">
          <el-input placeholder="请输入文件名称（可不填）" v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="文件格式" label-width="120px">
          <el-select v-model="form.region" placeholder="请选择">
            <el-option label="DOCX" value="1"></el-option>
            <el-option label="XLSX" value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="isAddDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitBtn">确 定</el-button>
      </div>
    </el-dialog>
    <!-- <div v-else class="view-file">
      <div class="box">
        <el-button type="primary" size="small" @click="downLoadFile">下载</el-button>
        <el-button type="danger" size="small" @click="deleteFile">删除</el-button>
      </div>
      <iframe :src="`https://view.officeapps.live.com/op/view.aspx?src=${file_data.filePath}`" :key="file_data.id" frameborder="0"></iframe>
    </div> -->
  </div>
</template>

<style lang="less" scoped>
.module_main_document {
  margin: 16px 0;
  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 4px 2px rgba(38,90,225,0.15);
  padding: 10px 0;
  min-height: 500px;
  text-align: center;
  position: relative;
  .box {
    position: absolute;
    top: 20px;
    right: 20px;
  }
  .tips{
    margin-bottom: 20px;
    img {
      width: 94px;
    }
    h3 {
      color: #191F2E;
      font-size: 20px;
      line-height: 24px;
      margin: 16px auto 8px auto;
    }
    p {
      color: #969EB4;
      font-size: 14px;
    }
  }
  .btn {
    background: linear-gradient(0deg,rgba(51,128,254,1),rgba(20,64,255,1));
    border-radius: 6px;
    min-width: 140px;
  }
  .view-file{
    width: 100%;
    height: 750px;
    overflow: hidden;

    iframe{
      position: relative;
      // top: -90px;
      width: 100%;
      height: 110%;
    }
  }

  .delete {
    float: right;
    margin: 0 10px;
  }

  .addFile {
    margin-top: 10px;
  }
}

</style>
