<template>
  <div class="present" ref="printDom">
    <div class="title">
      <p>海南省南繁管理局</p>
      <p>收文呈批单</p>
    </div>
    <div class="table">
      <div class="row">
        <div class="metre">来文单位</div>
        <div class="input" :style="{width:'362px'}">
          <el-input
            v-if="!isDrafter"
            type="textarea"
            :rows="2"
            placeholder
            maxlength="40"
            v-model="presentForm.draftUnit"
            :disabled="isDrafter"
          ></el-input>
          <div class="echo_box" v-else>
            <span class="echo_text">{{presentForm.draftUnit}}</span>
          </div>
        </div>
        <div class="metre">收文日期</div>
        <div class="input finally">
          <el-date-picker v-model="presentForm.receiptDate" type="date" value-format="yyyy-MM-dd" :disabled="isDrafter"></el-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="metre">办文编号</div>
        <div class="input" :style="{width:'362px'}">
          <el-input
            v-if="!isDrafter"
            type="textarea"
            :rows="2"
            placeholder
            maxlength="40"
            v-model="presentForm.officeNumber"
            :disabled="isDrafter"
          ></el-input>
          <div class="echo_box" v-else>
            <span class="echo_text">{{presentForm.officeNumber}}</span>
          </div>
        </div>
        <div class="metre">密级及保密期</div>
        <div class="input finally">
           <el-select v-model="presentForm.confidentialityPeriod" placeholder disabled>
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="row">
        <div class="metre">来文字号</div>
        <div class="input official">
          <el-input v-if="!isDrafter" v-model="presentForm.incomingCharacterSize" type="textarea"
            :rows="2" maxlength="85" :disabled="isDrafter"></el-input>
          <div class="echo_box" v-else>
            <span class="echo_text">{{presentForm.incomingCharacterSize}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="metre">公文标题</div>
        <div class="input official">
          <el-input v-if="!isDrafter" v-model="presentForm.documentTitle" type="textarea"
            :rows="2" maxlength="85" :disabled="isDrafter"></el-input>
          <div class="echo_box" v-else>
            <span class="echo_text">{{presentForm.documentTitle}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="opinion">
          <p>拟办意见：</p>
          <div class="textList" v-for="(item, i) in proposedOpinionList" :key="i"  v-show="proposedOpinionList">
            <p><span>处理人: </span>{{item.realName}}</p>
            <p><span>填写意见:</span> {{item.handleOpinion}}</p>
            <p><span>处理时间: </span>{{item.approveDate}}</p>
          </div>
          <el-input
            :autosize="{ minRows: 5, maxRows: 100}"
            type="textarea"
            placeholder
            v-model="presentForm.proposedOpinion"
            :disabled="isProposedOpinion"
          ></el-input>
        </div>
        <div class="opinions del">
          <p>领导批示：</p>
          <div class="textList" v-for="(item, i) in officeOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            :autosize="{ minRows: 5, maxRows: 100}"
            type="textarea"
            placeholder
            v-model="presentForm.officeOpinion"
            :disabled="isOfficeOpinion "
          ></el-input>
        </div>
      </div>
      <div class="row">
        <div class="opinion del">
          <p>办公室主任意见：</p>
          <div class="textList" v-for="(item, i) in officeDirectorOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            :autosize="{ minRows: 5, maxRows: 100}"
            type="textarea"
            placeholder
            v-model="presentForm.officeDirectorOpinion"
            :disabled="isOfficeDirectorOpinion"
          ></el-input>
        </div>
        <!-- <div class="opinions del">
          <p>常务副局长意见：</p>
          <div class="textList" v-for="(item, i) in directorOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            :autosize="{ minRows: 5, maxRows: 100}"
            type="textarea"
            placeholder
            v-model="presentForm.directorOpinion"
            :disabled="isDirectorOpinion"
          ></el-input>
        </div> -->
      </div>
       <!-- 通过/驳回弹框 -->
      <el-button type="text" @click="centerDialogVisible = true"></el-button>
      <el-dialog title="请选择审批结果" :visible.sync="centerDialogVisible">
        <el-form :model="form">
          <el-form-item label="审批结果" :label-width="formLabelWidth">
            <el-select v-model="form.region" placeholder="请选择">
              <el-option label="通过" value="1"></el-option>
              <el-option label="驳回" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitBtn" :disabled="isEnds">确 定</el-button>
        </div>
      </el-dialog>
      <!-- 是否办结弹框  -->
      <el-button type="text" @click="isEndDialogVisible = true"></el-button>
      <el-dialog title="请选择是否办结公文" :visible.sync="isEndDialogVisible">
        <el-form :model="isEndForm">
          <el-form-item label="办结选择" :label-width="formLabelWidth">
            <el-select v-model="isEndForm.region" placeholder="请选择">
              <el-option label="办结" value="1" ></el-option>
              <el-option label="不办结" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="isEndDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="isEndBtn" :disabled="isEnds">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import vue from 'vue';
import print from '../print';
vue.use(print);

export default {
  props: [
    'child_data',
    'nodeCode',
    'nodeName',
    'sendingList',
    'presentForm',
    'automaticApprovalPerson',
    'approveSort',
    'enclosureId',
    'textId',
    'area',
    'currentNode',
    'currentApproveSort',
    'currentCode',
    'currentHandle',
    'fatherFinish',
    'fatherDetails',
    'getDocumentId',
  ],
  data() {
    return {
      input: '',
      value1: '',
      options: [
        {
          value: '1',
          label: '普通'
        },
        {
          value: '0',
          label: '一般'
        }
      ],
      option: [
        {
          value: '0',
          label: '一般'
        },
        {
          value: '1',
          label: '急'
        },
        {
          value: '2',
          label: '紧急'
        }
      ],
      optio: [
        {
          value: '0',
          label: '公开'
        },
        {
          value: '1',
          label: '主动公开'
        },
        {
          value: '2',
          label: '依申请公开'
        },
        {
          value: '3',
          label: '不予公开'
        }
      ],
      id: '', // 保存草稿获取公共呈批单id
      isDrafter: false, // 是否是拟稿人
      isProposedOpinion: true, // 拟办意见
      proposedOpinionList: [], // 拟办意见列表
      isOfficeDirectorOpinion: true, // 主任意见 - 办公室主任
      officeDirectorOpinionList: [], // 主任意见列表
      isOfficeOpinion: true, // 相关处室意见 - 分管局领导 
      officeOpinionList: [], // 相关处室意见列表
      isDirectorOpinion: true, // 南繁管理局意见
      directorOpinionList: [], // 南繁管理局意见 - 常务副局长
      centerDialogVisible: false, // 弹框确认是否通过/驳回
      handleStatus: 1, // 处理状态:0-驳回,1-同意
      isComfirm: false,
      form: {
        name: '',
        region: '1',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      isEndForm: {
        name: '',
        region: '1',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      isEnds: false, // 办结禁止点击
      handleOpinion: '', // 审批意见详情
      opinionType: '', // 意见类型
      formLabelWidth: '120px',
      isEnd: false, // 相关处室意见是否办结
      isEndDialogVisible: false
    };
  },
  mounted() {
    this.value = this.$route.query.code;
    this.documentId = this.$route.query.id;
    this.operationType = this.$route.query.type;
    if (this.fatherDetails.opinionList) {
      this.proposedOpinionList = this.fatherDetails.opinionList.proposedOpinion || []
      this.officeDirectorOpinionList = this.fatherDetails.opinionList.officeDirectorOpinion || []
      this.officeOpinionList = this.fatherDetails.opinionList.officeOpinion || []
      this.directorOpinionList = this.fatherDetails.opinionList.directorOpinion || []
    }
  },

  methods: {
    print() {
      const dom = this.$refs.printDom;
      this.$print(dom);
    },
    // 弹框确定按钮
    submitBtn() {
      if (this.form.region == '1') {
        this.handleStatus = 1;
        if ((this.currentCode === 'SW_NBYJ' || this.currentCode === 'SW_A_NBYJ' || 
        this.currentCode === 'SW_B_NBYJ' || this.currentCode === 'SW_C_NBYJ'
        || this.currentCode === 'SW_D_NBYJ')&& !this.presentForm.proposedOpinion) {
          this.presentForm.proposedOpinion = '通过';
        } else if (this.currentCode === 'SW_BGSZR' && !this.presentForm.officeDirectorOpinion) {
          this.presentForm.officeDirectorOpinion = '通过';
        } 
        // else if (this.currentCode === 'SW_JZHFJZ' && !this.presentForm.directorOpinion) {
        //   this.presentForm.directorOpinion = '通过';
        // } 
         else if ((this.currentCode === 'SW_FGJLD' || this.currentCode === 'SW_CWFJZ') && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '通过';
        } 
        // else if (this.currentCode === 'SW_CWFJZ' && !this.presentForm.directorOpinion) {
        //   this.presentForm.directorOpinion = '通过';
        //   // this.presentForm.directorOpinion = '通过';
        // } 
        // else if ((this.currentCode === 'SW_A_XGCSYJ' || this.currentCode === 'SW_B_XGCSYJ' ||
        // this.currentCode === 'SW_C_XGCSYJ' || this.currentCode === 'SW_D_XGCSYJ') && !this.presentForm.officeOpinion) {
        //   this.presentForm.officeOpinion = '通过';
        // } 

      } else {
        this.handleStatus = 0;
        if ((this.currentCode === 'SW_NBYJ' || this.currentCode === 'SW_A_NBYJ' ||
        this.currentCode === 'SW_B_NBYJ' || this.currentCode === 'SW_C_NBYJ'
        || this.currentCode === 'SW_D_NBYJ') && !this.presentForm.proposedOpinion) {
          this.presentForm.proposedOpinion = '驳回';
        } else if (this.currentCode === 'SW_BGSZR' && !this.presentForm.officeDirectorOpinion) {
          this.presentForm.officeDirectorOpinion = '驳回';
        } else if ((this.currentCode === 'SW_FGJLD' || this.currentCode === 'SW_CWFJZ') && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '驳回';
        } 
        // else if (this.currentCode === 'SW_CWFJZ' && !this.presentForm.directorOpinion) {
        //   this.presentForm.directorOpinion = '驳回';
        //   // this.presentForm.directorOpinion = '驳回';
        // } 
        // else if ((this.currentCode === 'SW_A_XGCSYJ' || this.currentCode === 'SW_B_XGCSYJ' ||
        // this.currentCode === 'SW_C_XGCSYJ' || this.currentCode === 'SW_D_XGCSYJ') && !this.presentForm.officeOpinion) {
        //   this.presentForm.officeOpinion = '驳回';
        // }
      }
      this.centerDialogVisible = false;
      this.addListSubmitLast();
      this.isEnds = true; // 防止多次点击
    },
    // 是否办结弹框确定按钮
    isEndBtn() {
      this.handleStatus = 1
      if (this.isEndForm.region == '1') {
        this.isEnd = true;
      } else {
        this.isEnd = false;
        if (this.child_data == '' && (this.nodeCode === 'SW_A_XGCSYJ' || this.nodeCode === 'SW_B_XGCSYJ'
        || this.nodeCode === 'SW_C_XGCSYJ' || this.nodeCode === 'SW_D_XGCSYJ' || this.nodeCode === 'SW_E_XGCSYJ')) {
          this.$message('请选择相关处室（单位）处理人');
          return false;
        }
      }
      this.isEndDialogVisible = false;
      this.addListSubmitLast();
      this.isEnds = true; // 防止多次点击
    },
    // 保存草稿
    async addList() {
      const params = {
        // 呈批单添加草稿入参
        // 附件列表
        attachedFileList: this.enclosureId,
        // 呈批单公共数据
        commonDocumentApply: {
          bureauLeaderOpinion: '', // 分管局领导意见
          directorOpinion: '', // 局长意见
          documentArea: this.area, // 公文所属省/市
          documentStatus: this.presentForm.documentStatus, // 发送状态(1-草稿箱,2-已发送,3-已同意,4-已驳回)
          documentTitle: this.presentForm.documentTitle, // 公文标题
          documentType: this.value, // 呈批单类型
          draftDate: this.presentForm.draftDate, // 拟稿时间/申请日期
          draftPersonPhone: this.presentForm.draftPersonPhone, // 拟稿人及电话
          draftUnit: this.presentForm.draftUnit, // 拟稿单位/处室/人
          emergencyLevel: this.presentForm.emergencyLevel, // 紧急程度(0-一般,1-急,2-紧急)
          officeDirectorOpinion: '', // 办公室主任意见
          officeOpinion: '', // 相关处室意见
          sendTime: this.presentForm.sendTime // 发送时间
        },
        // 正文
        documentText: {
          applyId: '', // 呈批单id
          essayType: 0, // 文章类型 1正文 2附件
          fileId: this.textId // 文件id
        },
        // 收文呈批单
        officialReceiptApplyReq: {
          confidentialityPeriod: this.presentForm.confidentialityPeriod, // 密级及保密期(0一般，1普通)
          documentId: '', // 公文呈批单id
          incomingCharacterSize: this.presentForm.incomingCharacterSize, // 来文字号
          officeNumber: this.presentForm.officeNumber, // 办文编号
          proposedOpinion: '', // 拟办意见
          receiptDate: this.presentForm.receiptDate // 收文时间
        }
      };
      const api = this.$fetchApi.insertDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      this.id = data.message;
    },
    // 发送
    async addListSubmit() {
      if (this.presentForm.draftUnit == undefined) {
        this.$message('请输入来文单位');
        return;
      }
      if (this.presentForm.receiptDate == undefined) {
        this.$message('请选择收文日期');
        return;
      }
      if (this.presentForm.officeNumber == undefined) {
        this.$message('请输入办文编号');
        return;
      }
      if (this.presentForm.documentTitle == undefined) {
        this.$message('请输入公文标题');
        return;
      }
      if (this.currentCode === 'DRAFT_MAN' && this.presentForm.proposedOpinion == undefined) {
        this.$message('请输入拟办意见');
        return;
      }
      if (this.nodeCode === 'SW_A_XGCSYJ' || this.nodeCode === 'SW_B_XGCSYJ'
       || this.nodeCode === 'SW_C_XGCSYJ' || this.nodeCode === 'SW_D_XGCSYJ'
        || this.nodeCode === 'SW_E_XGCSYJ') {
        // if ((!this.presentForm.proposedOpinion && this.currentCode !== 'SW_C_GWLZ') ||
        //  (!this.presentForm.proposedOpinion && this.currentCode !== 'SW_B_GWLZ')) {
        //   this.$message('请填写拟办意见');
        // } else 
        if (!this.presentForm.directorOpinion && this.currentCode === 'SW_CWFJZ') {
          this.$message('请填写领导批示意见');
        } else {
          this.isEndDialogVisible = true;
        }
        return;
      }
      if (this.currentNode !== '拟稿' && this.nodeCode !== 'FINISH') {
        this.centerDialogVisible = true;
      } else if (!this.presentForm.proposedOpinion && this.nodeCode === 'FINISH') {
        this.$message('请填写拟办意见');
      } else {
        this.isConfirm = true;
        this.$emit('childFn', this.isConfirm);
        this.addListSubmitLast();
      }
    },
    // 发送接口 - 提取出来谁用谁调
    async addListSubmitLast() {
      let operationType = 1;
      if (this.currentNode == [] || this.currentNode == '拟稿') {
        operationType = 1;
      } else {
        operationType = 2;
      }
      if (
        this.presentForm.documentStatus === '1' ||
        this.presentForm.documentStatus === '2'
      ) {
        await this.revise();
        console.log('修改草稿');
      } else if (
        this.presentForm.documentStatus === '3' ||
        this.presentForm.documentStatus === '4'
      ) {
        return false;
      } else {
        await this.addList();
        console.log('新增保存草稿');
      }
      // 判断意见输入的内容 审批意见详情
      if (this.currentCode === 'SW_NBYJ' || this.currentCode === 'SW_A_NBYJ'
        || this.currentCode === 'SW_B_NBYJ' || this.currentCode === 'SW_C_NBYJ'
        || this.currentCode === 'SW_D_NBYJ'|| this.currentCode === 'DRAFT_MAN'
        || this.currentCode === 'SW_A_XGCSYJ' || this.currentCode === 'SW_B_XGCSYJ'
        || this.currentCode === 'SW_C_XGCSYJ' || this.currentCode === 'SW_D_XGCSYJ'
        || this.currentCode === 'SW_E_XGCSYJ') {
        this.handleOpinion = this.presentForm.proposedOpinion
        this.opinionType = 'proposedOpinion' // 拟办意见
      } else if (this.currentCode === 'SW_BGSZR') {
        this.handleOpinion = this.presentForm.officeDirectorOpinion
        this.opinionType = 'officeDirectorOpinion' // 办公室主任意见
      } else if (this.currentCode === 'SW_FGJLD' || this.currentCode === 'SW_CWFJZ') {
        // this.currentCode === 'SW_JZHFJZ' || 
        this.handleOpinion = this.presentForm.officeOpinion
        this.opinionType = 'officeOpinion' // 分管局领导意见 - 领导批示
      } 
      // else if (this.currentCode === 'SW_CWFJZ') {
      //   this.handleOpinion = this.presentForm.directorOpinion
      //   this.opinionType = 'directorOpinion' // 常务副局长意见
      // } 
      // else if (this.currentCode === 'SW_A_XGCSYJ' || this.currentCode === 'SW_B_XGCSYJ' || this.currentCode === 'SW_C_XGCSYJ'
      // || this.currentCode === 'SW_D_XGCSYJ' ) {
      //   this.handleOpinion = this.presentForm.officeOpinion
      //   this.opinionType = 'officeOpinion' // 相关处室意见
      // }
      // 两个之间相差为一自动审批列表-无审批人节点设置为空
      if (this.currentApproveSort + 1 === this.approveSort) {
        this.automaticApprovalPerson = [];
      } 
      const params = {
        automaticApprovalPerson: this.automaticApprovalPerson, // 自动审批列表-无审批人节点
        commonDocumentId: this.id, // 公共呈批单id
        documentArea: this.area, // 公文所属省/市
        documentType: this.value, // 呈批单类型
        handleStatus: this.handleStatus, // 处理状态:0-驳回,1-同意
        memberIdList: this.sendingList, // 发文对象id列表-仅在请示呈批单/发文呈批单/收文呈批单类型需要传参
        operationType: operationType, // 呈批单操作类型:1-拟稿人发送,2-拟文流转处理,3-传阅操作
        endFlag: this.isEnd, // 相关处室意见是否办结
        // 当前流转节点信息
        currentDocumentNodeReq: {
          approveSort: this.currentApproveSort, // 当前流转节点排序
          circulationCode: this.currentCode, // 当前流转节点编号
          handleMemberId: this.currentHandle, // 当前节点处理人用户id,必填,不可传无
          nodeName: this.currentNode // 当前流转节点名称
        },
        // 下一流转节点信息
        nextDocumentNodeReq: {
          approveSort: !this.isEnd ? this.approveSort : '',
          circulationCode: !this.isEnd ? this.nodeCode : '',
          handleMemberId: !this.isEnd ? this.child_data : '',
          nodeName: !this.isEnd ? this.nodeName : ''
        },
        // 意见列表
        opinionListReq: [ 
          {
            handleOpinion: this.handleOpinion, // 审批意见详情
            opinionType: this.opinionType // 意见类型 流转节点编号
          }
        ]
      };
      const api = this.$fetchApi.sendDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      if (data.code == '200') {
        window.localStorage.removeItem('file');
        window.localStorage.removeItem('files');
        this.$message({
          message: '发送成功',
          type: 'success'
        });
        if (this.presentForm.documentStatus || this.presentForm.documentStatus == '1') {
          this.$router.push('/documentText');
        } else {
          this.$router.push('/proposedText');
        }
      }
    },
    // 修改草稿
    async revise() {
      let operationType = 1;
      if (this.presentForm.documentStatus == '4') {
        operationType = 2;
      } else {
        operationType = 1;
      }
      const params = {
        // 呈批单添加草稿入参
        // 附件列表
        attachedFileList: this.enclosureId,
        // 呈批单公共数据
        commonDocumentApply: {
          bureauLeaderOpinion: '', // 分管局领导意见
          directorOpinion: '', // 局长意见
          documentArea: this.area, // 公文所属省/市
          documentStatus: this.presentForm.documentStatus, // 发送状态(1-草稿箱,2-已发送,3-已同意,4-已驳回)
          documentTitle: this.presentForm.documentTitle, // 公文标题
          documentType: this.value, // 呈批单类型
          draftDate: this.presentForm.draftDate, // 拟稿时间/申请日期
          draftPersonPhone: this.presentForm.draftPersonPhone, // 拟稿人及电话
          draftUnit: this.presentForm.draftUnit, // 拟稿单位/处室/人
          emergencyLevel: this.presentForm.emergencyLevel, // 紧急程度(0-一般,1-急,2-紧急)
          officeDirectorOpinion: '', // 办公室主任意见
          officeOpinion: '', // 相关处室意见
          sendTime: this.presentForm.sendTime, // 发送时间
        },
        commonDocumentId: this.presentForm.id, // 公共呈批单数据id
        documentId:
          this.presentForm.contractId ||
          this.presentForm.postId ||
          this.presentForm.receiptId, // 呈批单数据id
        documentType: this.value, // 呈批单类型
        operationType: operationType, // 操作类型,1-普通修改,2-驳回后编辑
        // 正文
        documentText: {
          applyId: '', // 呈批单id
          essayType: 1, // 文章类型 1正文 2附件
          fileId: this.textId // 文件id
        },
        // 收文呈批单
        officialReceiptApplyReq: {
          confidentialityPeriod: this.presentForm.confidentialityPeriod, // 密级及保密期(0一般，1普通)
          documentId: this.documentId, // 公文呈批单id
          incomingCharacterSize: this.presentForm.incomingCharacterSize, // 来文字号
          officeNumber: this.presentForm.officeNumber, // 办文编号
          proposedOpinion: '', // 拟办意见
          receiptDate: this.presentForm.receiptDate // 收文时间
        }
      };
      const api = this.$fetchApi.updateDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      this.id = this.documentId;
    },
    // 发送接口 - 传阅发送
    async circulateBtn() {
      let operationType = 3;
      // 两个之间相差为一自动审批列表-无审批人节点设置为空
      if (this.currentApproveSort + 1 === this.approveSort) {
        this.automaticApprovalPerson = [];
      } 
      const params = {
        automaticApprovalPerson: this.automaticApprovalPerson, // 自动审批列表-无审批人节点
        commonDocumentId: this.getDocumentId, // 公共呈批单id
        documentArea: this.area, // 公文所属省/市
        documentType: this.value, // 呈批单类型
        handleStatus: this.handleStatus, // 处理状态:0-驳回,1-同意
        memberIdList: this.sendingList, // 发文对象id列表-仅在请示呈批单/发文呈批单/收文呈批单类型需要传参
        operationType: operationType, // 呈批单操作类型:1-拟稿人发送,2-拟文流转处理,3-传阅操作
        // 当前流转节点信息
        currentDocumentNodeReq: {
          approveSort: this.currentApproveSort, // 当前流转节点排序
          circulationCode: this.currentCode, // 当前流转节点编号
          handleMemberId: this.currentHandle, // 当前节点处理人用户id,必填,不可传无
          nodeName: this.currentNode // 当前流转节点名称
        },
        // 下一流转节点信息
        nextDocumentNodeReq: {
          approveSort: this.approveSort,
          circulationCode: this.nodeCode,
          handleMemberId: this.child_data,
          nodeName: this.nodeName
        }
      };
      const api = this.$fetchApi.sendDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      if (data.code == '200') {
        this.$message({
          message: '发送成功',
          type: 'success'
        });
        this.$router.push('/documentText');
      }
    }
  },
 
  watch: {
    currentCode: {
      handler(val) {
        this.isProposedOpinion = true;
        // console.log(this.isProposedOpinion)
        // console.log(this.nodeCode)
        if (val === 'DRAFT_MAN') {
          this.isDrafter = false;
        } else {
          this.isDrafter = true;
        }
        if (val === 'SW_NBYJ' || val === 'DRAFT_MAN' || val === 'SW_A_NBYJ' || val === 'SW_B_NBYJ'
        || val === 'SW_C_NBYJ' || val === 'SW_D_NBYJ' || val === 'SW_A_XGCSYJ' || val === 'SW_B_XGCSYJ'
        || val === 'SW_C_XGCSYJ' || val === 'SW_D_XGCSYJ' || val === 'SW_E_XGCSYJ') {
          this.isProposedOpinion = false; // 拟办意见
        }
        if (val === 'SW_BGSZR') {
          this.isOfficeDirectorOpinion = false; // 办公室主任意见
        }
        // val === 'SW_JZHFJZ' || 
        if (val === 'SW_FGJLD' || val === 'SW_CWFJZ') {
          this.isOfficeOpinion = false; // 分管局领导意见
        }
        // if (val === 'SW_CWFJZ') {
        //   this.isDirectorOpinion = false; // 常务副局长
        // }
        // if (val === 'SW_A_XGCSYJ' || val === 'SW_B_XGCSYJ' || val === 'SW_C_XGCSYJ' || val === 'SW_D_XGCSYJ') {
        //   this.isOfficeOpinion = false;
        // }
      },
      immediate: true
    },
    fatherDetails: {
      handler(val) {
        // 意见列表回显
        if (val.opinionList) {
          this.proposedOpinionList = val.opinionList.proposedOpinion || []
          this.officeDirectorOpinionList = val.opinionList.officeDirectorOpinion || []
          this.officeOpinionList = val.opinionList.officeOpinion || []
          this.directorOpinionList = val.opinionList.directorOpinion || []
        }
      }
    },
  }
};
</script>
<style lang="less" scoped>
.title {
  margin: 10px 0;
  display: flex;
  font-size: 22px;
  color: red;
  justify-content: space-around;
}
.table {
  font-size: 16px;
  color: red;
  margin: 10px 0;
  width: 758px;
  min-height: 200px;
  border: 1px solid red;
  .rows {
    border-bottom: 1px solid red;
  }
  .row {
    display: flex;
    .opinion {
      border-right: 1px solid red;
      border-bottom: 1px solid red;
      width: 375px;
      height: auto;
      overflow: hidden;
      p {
        margin-left: 10px;
      }

      .el-textarea {
        height: 90%;
      }
      /deep/.el-textarea__inner {
        border: none;
        // height: 200px;
        height: 100%;
        width: 99%;
        margin-left: 1px;
        resize: none;
      }
    }
    .opinions {
      border-bottom: 1px solid red;
      width: 382px;
      // min-height: 420px;
      // max-height: 1000px;
      // overflow: hidden;
      p {
        margin-left: 10px;
      }
      .el-textarea {
        height: 90%;
      }
      /deep/.el-textarea__inner {
        border: none;
        height: 100%;
        resize: none;
        width: 99%;
        margin-left: 1px;
        overflow:hidden
      }
    }
    .del {
      border-bottom: none;
    }
    .metre {
      border-right: 1px solid red;
      border-bottom: 1px solid red;
      width: 80px;
      height: 55px;
      text-align: center;
      line-height: 55px;
    }
    .feed {
      width: 50px;
      height: 35px;
      line-height: 20px;
      padding: 10px 15px;
    }
  }
  .input {
    border-right: 1px solid red;
    border-bottom: 1px solid red;
    width: 130px;
    overflow: hidden;

    /deep/.el-input__inner {
      height: 55px;
      border: none;
      font-size: 16px;
    }
    /deep/.el-date-editor--date {
      width: 99%;
    }
    /deep/.el-input__prefix,
    /deep/.el-input__suffix-inner {
      display: none;
    }
    /deep/.el-input__inner {
      padding: 0 15px;
      width: 99%;
      text-align: center;
    }
    /deep/.el-textarea__inner {
      min-height: 55px !important;
      border: none;
      height: 55px;
      // padding-top: 18px;
      resize: none;
      width: 99%;
      text-align: center;
    }
  }
  .middle {
    width: 150px;
  }
  .finally {
    width: 235px;
    border-right: none;
  }
  .copies {
    width: 85px;
    border-right: none;
  }
  .official {
    width: 679px;
    max-height: 110px;
    overflow: hidden;
    border-right: none;
  }
}

.centers {
  text-align: center;
}

.textList {
  font-size: 14px;
  color: #757575;
  margin: 5px;
}
/deep/ .el-textarea.is-disabled .el-textarea__inner,
/deep/ .el-input.is-disabled .el-input__inner {
  color: #757575;
  // line-height: 20px;
}

.echo_box {
  display: table;
  background-color: #f5f7fa;
  height: 100%;
  width: 100%;
  color: #757575;
  font-size: 14px;
}

.echo_text {
  display: table-cell;
  vertical-align:middle;
  text-align:center;
}
</style>