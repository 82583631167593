<template>
  <div class="present" ref="printDom">
    <div class="title">
      <p>海南省南繁管理局</p>
      <p>请示呈批单</p>
    </div>
    <div class="table">
      <div class="row">
        <div class="metre">拟稿单位</div>
        <div class="input" :style="{width:'294px'}">
          <el-input
            v-if="!isDrafter"
            type="textarea"
            :rows="2"
            placeholder
            maxlength="30"
            v-model="presentForm.draftUnit"
            :disabled="isDrafter"
          ></el-input>
          <div class="echo_box" v-else>
            <span class="echo_text">{{presentForm.draftUnit}}</span>
          </div>
        </div>
        <div class="metre">拟稿时间</div>
        <div class="input finally" :style="{width:'300px'}">
          <el-date-picker v-model="presentForm.draftDate" type="date" value-format="yyyy-MM-dd" :disabled="isDrafter"></el-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="metre feed">拟稿人及电话</div>
        <div class="input" :style="{width:'294px'}">
          <el-input
            v-if="!isDrafter"
            type="textarea"
            :rows="2"
            placeholder
            maxlength="30"
            v-model="presentForm.draftPersonPhone"
            :disabled="isDrafter"
          ></el-input>
          <div class="echo_box" v-else>
            <span class="echo_text">{{presentForm.draftPersonPhone}}</span>
          </div>
        </div>
        <div class="metre">办文编号</div>
        <div class="input finally" :style="{width:'300px'}">
          <el-input
            type="text"
            :rows="2"
            placeholder
            maxlength="20"
            v-model="presentForm.officeNumber"
            disabled
          ></el-input>
        </div>
      </div>
      <div class="row">
        <!-- <div class="metre">公文标题</div>
        <div class="input official">
          <el-input v-model="presentForm.documentTitle" maxlength="30" :disabled="isDrafter"></el-input>
        </div> -->
        <div class="metre">公文标题</div>
        <div class="input official">
          <el-input v-if="!isDrafter" v-model="presentForm.documentTitle" type="textarea"
            :rows="2" maxlength="90" :disabled="isDrafter"></el-input>
          <div class="echo_box" v-else>
            <span class="echo_text">{{presentForm.documentTitle}}</span>
          </div>
        </div> 
      </div>
      <div class="row">
        <div class="opinion">
          <p>拟办意见：</p>
          <div class="textList" v-for="(item, i) in proposedOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            type="textarea"
            placeholder
            :autosize="{ minRows: 5, maxRows: 100}"
            v-model="presentForm.proposedOpinion"
            :disabled="isSuggestions">
          </el-input>
        </div>
        <div class="opinions del">
          <p>处室意见：</p>
          <div class="textList" v-for="(item, i) in officeOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            type="textarea"
            placeholder
            :autosize="{ minRows: 5, maxRows: 100}"
            v-model="presentForm.officeOpinion"
            :disabled="isOfficeOpinion"
          ></el-input>
        </div>
      </div>
      <div class="row">
        <div class="opinionss del">
          <p>局领导批示：</p>
          <div class="textList" v-for="(item, i) in bureauLeaderOpinionList" :key="i">
            <p>处理人: {{item.realName}}</p>
            <p>填写意见: {{item.handleOpinion}}</p>
            <p>处理时间: {{item.approveDate}}</p>
          </div>
          <el-input
            type="textarea"
            placeholder
            :autosize="{ minRows: 5, maxRows: 100}"
            v-model="presentForm.directorOpinion"
            :disabled="isBureauLeaderOpinion"
          ></el-input>
        </div>
      </div>
      <el-button type="text" @click="centerDialogVisible = true"></el-button>
      <el-dialog title="请选择审批结果" :visible.sync="centerDialogVisible">
        <el-form :model="form">
          <el-form-item label="审批结果" :label-width="formLabelWidth">
            <el-select v-model="form.region" placeholder="请选择">
              <el-option label="通过" value="1"></el-option>
              <el-option label="驳回" value="2"></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitBtn">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import vue from 'vue';
import print from '../print';
vue.use(print);

export default {
  props: [
    'child_data',
    'nodeCode',
    'nodeName',
    'sendingList',
    'presentForm',
    'automaticApprovalPerson',
    'approveSort',
    'enclosureId',
    'textId',
    'area',
    'currentNode',
    'currentApproveSort',
    'currentCode',
    'currentHandle',
    'fatherFinish',
    'fatherDetails',
    'getDocumentId'
  ],
  data() {
    return {
      input: '',
      value1: '',
      options: [
        {
          value: '1',
          label: '普通'
        },
        {
          value: '0',
          label: '一般'
        }
      ],
      option: [
        {
          value: '0',
          label: '一般'
        },
        {
          value: '1',
          label: '急'
        },
        {
          value: '2',
          label: '紧急'
        }
      ],
      optio: [
        {
          value: '0',
          label: '公开'
        },
        {
          value: '1',
          label: '主动公开'
        },
        {
          value: '2',
          label: '依申请公开'
        },
        {
          value: '3',
          label: '不予公开'
        }
      ],
      value: '', // 呈批单类型
      id: '', // 保存草稿获取公共呈批单id
      documentId: '',
      isDrafter: false, // 是否是拟稿人
      isSuggestions: true, // 拟办意见
      proposedOpinionList: [], // 拟办意见列表
      isBureauLeaderOpinion: true, // 局领导批示
      bureauLeaderOpinionList: [], // 局领导批示列表
      isOfficeOpinion: true, // 处室意见
      officeOpinionList: [], // 处室意见列表
      opinionListReq: [], // 意见列表
      centerDialogVisible: false,
      handleStatus: 1, // 处理状态:0-驳回,1-同意
      isConfirm: false,
      form: {
        name: '',
        region: '1',
        date1: '',
        date2: '',
        delivery: false,
        type: [],
        resource: '',
        desc: ''
      },
      formLabelWidth: '120px'
    };
  },
  methods: {
    print() {
      const dom = this.$refs.printDom;
      this.$print(dom);
    },
    // 弹框确定按钮
    submitBtn() {
      if (this.form.region == '1') {
        this.handleStatus = 1;
        if (this.currentCode === 'QS_BCSYJ' && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '通过';
        } else if (this.currentCode === 'QS_XGCSYJ' && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '通过';
        } else if (this.currentCode === 'QS_BGSZR' && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '通过';
        } else if (this.currentCode === 'QS_FGJLD' && !this.presentForm.directorOpinion) {
          this.presentForm.directorOpinion = '通过';
        } else if (this.currentCode === 'QS_CWFJZ' && !this.presentForm.directorOpinion) {
          this.presentForm.directorOpinion = '通过';
        } else if (this.currentCode === 'QS_JZ' && !this.presentForm.directorOpinion) {
          this.presentForm.directorOpinion = '通过';
        } else if (this.currentCode === 'QS_HGR' && !this.presentForm.proposedOpinion) {
          this.presentForm.proposedOpinion = '通过';
        } 
        if ((this.currentCode === 'QS_BGSA' || this.currentCode === 'QS_BGSB' || this.currentCode === 'QS_BGSC' ||
        this.currentCode === 'QS_BGSD') && !this.presentForm.proposedOpinion) {
          this.presentForm.proposedOpinion = '通过';
        } 
        if ((this.currentCode === 'QS_BGSA' || this.currentCode === 'QS_BGSB' || this.currentCode === 'QS_BGSC' ||
        this.currentCode === 'QS_BGSD') && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '通过';
        }
      } else {
        this.handleStatus = 0;
        if (this.currentCode === 'QS_BCSYJ' && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '驳回';
        } else if (this.currentCode === 'QS_XGCSYJ' && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '驳回';
        } else if (this.currentCode === 'QS_BGSZR' && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '驳回';
        } else if ( this.currentCode === 'QS_FGJLD' && !this.presentForm.directorOpinion) {
          this.presentForm.directorOpinion = '驳回';
        } else if (this.currentCode === 'QS_CWFJZ' && !this.presentForm.directorOpinion) {
          this.presentForm.directorOpinion = '驳回';
        } else if (this.currentCode === 'QS_JZ' && !this.presentForm.directorOpinion) {
          this.presentForm.directorOpinion = '驳回';
        } else if (this.currentCode === 'QS_HGR' && !this.presentForm.proposedOpinion) {
          this.presentForm.proposedOpinion = '驳回';
        } 
        if ((this.currentCode === 'QS_BGSA' || this.currentCode === 'QS_BGSB' || this.currentCode === 'QS_BGSC' ||
        this.currentCode === 'QS_BGSD') && !this.presentForm.proposedOpinion) {
          this.presentForm.proposedOpinion = '驳回';
        } 
        if ((this.currentCode === 'QS_BGSA' || this.currentCode === 'QS_BGSB' || this.currentCode === 'QS_BGSC' ||
        this.currentCode === 'QS_BGSD') && !this.presentForm.officeOpinion) {
          this.presentForm.officeOpinion = '驳回';
        }
      }
      this.centerDialogVisible = false;
      this.addListSubmitLast();
    },
    // 保存草稿
    async addList() {
      const params = {
        // 呈批单添加草稿入参
        // 附件列表
        attachedFileList: this.enclosureId,
        // 呈批单公共数据
        commonDocumentApply: {
          bureauLeaderOpinion: '', // 分管局领导意见
          directorOpinion: '', // 局长意见
          documentArea: this.area, // 公文所属省/市
          documentStatus: this.presentForm.documentStatus, // 发送状态(1-草稿箱,2-已发送,3-已同意,4-已驳回)
          documentTitle: this.presentForm.documentTitle, // 公文标题
          documentType: this.value, // 呈批单类型
          draftDate: this.presentForm.draftDate, // 拟稿时间/申请日期
          draftPersonPhone: this.presentForm.draftPersonPhone, // 拟稿人及电话
          draftUnit: this.presentForm.draftUnit, // 拟稿单位/处室/人
          emergencyLevel: this.presentForm.emergencyLevel, // 紧急程度(0-一般,1-急,2-紧急)
          officeDirectorOpinion: '', // 办公室主任意见
          officeOpinion: '', // 相关处室意见
          sendTime: this.presentForm.sendTime // 发送时间
        },
        // 正文
        documentText: {
          applyId: '', // 呈批单id
          essayType: 1, // 文章类型 1正文 2附件
          fileId: this.textId // 文件id
        },
        // 请示呈批单
        officialRequestApply: {
          documentId: '', // 公文呈批单id
          officeNumber: '无', // 办文编号
          proposedOpinion: '' // 拟办意见
        }
      };
      const api = this.$fetchApi.insertDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      this.id = data.message;
    },
    // 发送
    async addListSubmit() {
      if (this.presentForm.draftUnit == undefined) {
        this.$message('请输入拟稿单位');
        return;
      }
      if (this.presentForm.draftDate == undefined) {
        this.$message('请选择拟稿时间');
        return;
      }
      if (this.presentForm.draftPersonPhone == undefined) {
        this.$message('请输入拟稿人及电话');
        return;
      }
      // if (this.presentForm.officeNumber == undefined) {
      //   this.$message('请输入办文编号');
      //   return;
      // }
      if (this.presentForm.documentTitle == undefined) {
        this.$message('请输入公文标题');
        return;
      }
      // if (this.child_data == '' && this.nodeCode !== 'FINISH' && this.nodeCode !== 'QS_XGCSYJ' && this.fatherFinish !== 'FINISH') {
      //   this.$message('请选择下一节点');
      //   return;
      // }
      if ((this.currentCode === 'QS_HGR' || this.currentCode === 'DRAFT_MAN') && this.presentForm.proposedOpinion == undefined) {
        this.$message('请输入拟办意见');
        return;
      }
      if (this.child_data == '' && this.nodeCode === 'QS_XGCSYJ') {
        this.$message('请选择相关处室（单位）处理人');
        return;
      }
      if (this.currentCode === 'QS_FWR' && this.sendingList.length == 0) {
        this.$message('请选择发文对象');
        return;
      }
      if (this.currentNode !== '拟稿') {
        this.centerDialogVisible = true;
        // this.currentCode === 'QS_BCSYJ' ||
        // this.currentCode === 'QS_XGCSYJ' ||
        // this.currentCode === 'QS_BGSZR' ||
        // this.currentCode === 'QS_FGJLD' ||
        // this.currentCode === 'QS_CWFJZ' ||
        // this.currentCode === 'QS_JZ' ||
        // this.currentCode === 'QS_BGSA' ||
        // this.currentCode === 'QS_BGSB' ||
        // this.currentCode === 'QS_BGSC' ||
        // this.currentCode === 'QS_BGSD' 
      } else {
        this.isConfirm = true;
        this.$emit('childFn', this.isConfirm);
        this.addListSubmitLast();
      }
    },
    // 发送接口 - 提取出来谁用谁调
    async addListSubmitLast() {
      let operationType = 1;
      if (this.currentNode == [] || this.currentNode == '拟稿') {
        operationType = 1;
      } else {
        operationType = 2;
      }
      if (
        this.presentForm.documentStatus === '1' ||
        this.presentForm.documentStatus === '2'
      ) {
        await this.revise();
        console.log('修改草稿');
      } else if (
        this.presentForm.documentStatus === '3' ||
        this.presentForm.documentStatus === '4'
      ) {
        return false;
      } else {
        await this.addList();
        console.log('新增保存草稿');
      }
      // 判断意见输入的内容 审批意见详情
      if (this.currentCode === 'QS_HGR' || this.currentCode === 'DRAFT_MAN') {
        this.opinionListReq = [{
          handleOpinion: this.presentForm.proposedOpinion,
          opinionType: 'proposedOpinion'
        }] // 拟办意见
      } else if (this.currentCode === 'QS_FGJLD' || this.currentCode === 'QS_CWFJZ' || this.currentCode === 'QS_JZ') {
        this.opinionListReq = [{
          handleOpinion: this.presentForm.directorOpinion,
          opinionType: 'directorOpinion'
        }] // 局领导意见
      } else if (this.currentCode === 'QS_BCSYJ' || this.currentCode === 'QS_XGCSYJ' || this.currentCode === 'QS_BGSZR') {
        this.opinionListReq = [{
          handleOpinion: this.presentForm.officeOpinion,
          opinionType: 'officeOpinion'
        }] // 处室意见
      } else if (this.currentCode === 'QS_BGSA' || this.currentCode === 'QS_BGSB' || this.currentCode === 'QS_BGSC' || this.currentCode === 'QS_BGSD') {
        this.opinionListReq = [
          {
            handleOpinion: this.presentForm.proposedOpinion,
            opinionType: 'proposedOpinion'
          },
          {
            handleOpinion: this.presentForm.officeOpinion,
            opinionType: 'officeOpinion'
          }
        ] // 拟办意见 处室意见
      }

      // 两个之间相差为一自动审批列表-无审批人节点设置为空
      if (this.currentApproveSort + 1 === this.approveSort) {
        this.automaticApprovalPerson = [];
      } 
      const params = {
        automaticApprovalPerson: this.automaticApprovalPerson, // 自动审批列表-无审批人节点
        commonDocumentId: this.id, // 公共呈批单id
        documentArea: this.area, // 公文所属省/市
        documentType: this.value, // 呈批单类型
        handleStatus: this.handleStatus, // 处理状态:0-驳回,1-同意
        memberIdList: this.sendingList, // 发文对象id列表-仅在请示呈批单/发文呈批单/收文呈批单类型需要传参
        operationType: operationType, // 呈批单操作类型:1-拟稿人发送,2-拟文流转处理,3-传阅操作
        // 当前流转节点信息
        currentDocumentNodeReq: {
          approveSort: this.currentApproveSort, // 当前流转节点排序
          circulationCode: this.currentCode, // 当前流转节点编号
          handleMemberId: this.currentHandle, // 当前节点处理人用户id,必填,不可传无
          nodeName: this.currentNode // 当前流转节点名称
        },
        // 下一流转节点信息
        nextDocumentNodeReq: {
          approveSort: this.approveSort,
          circulationCode: this.nodeCode,
          handleMemberId: this.child_data,
          nodeName: this.nodeName
        },
        // 意见列表
        opinionListReq: this.opinionListReq
      };
      const api = this.$fetchApi.sendDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      if (data.code == '200') {
        window.localStorage.removeItem('file');
        window.localStorage.removeItem('files');
        this.$message({
          message: '发送成功',
          type: 'success'
        });
        if (this.presentForm.documentStatus || this.presentForm.documentStatus == '1') {
          this.$router.push('/documentText');
        } else {
          this.$router.push('/proposedText');
        }
      }
    },
    // 修改草稿
    async revise() {
      let operationType = 1;
      if (this.presentForm.documentStatus == '4') {
        operationType = 2;
      } else {
        operationType = 1;
      }
      const params = {
        // 呈批单添加草稿入参
        // 附件列表
        attachedFileList: this.enclosureId,
        // 呈批单公共数据
        commonDocumentApply: {
          bureauLeaderOpinion: '', // 分管局领导意见
          directorOpinion: '', // 局长意见
          documentArea: this.area, // 公文所属省/市
          documentStatus: this.presentForm.documentStatus, // 发送状态(1-草稿箱,2-已发送,3-已同意,4-已驳回)
          documentTitle: this.presentForm.documentTitle, // 公文标题
          documentType: this.value, // 呈批单类型
          draftDate: this.presentForm.draftDate, // 拟稿时间/申请日期
          draftPersonPhone: this.presentForm.draftPersonPhone, // 拟稿人及电话
          draftUnit: this.presentForm.draftUnit, // 拟稿单位/处室/人
          emergencyLevel: this.presentForm.emergencyLevel, // 紧急程度(0-一般,1-急,2-紧急)
          officeDirectorOpinion: '', // 办公室主任意见
          officeOpinion: '', // 相关处室意见
          sendTime: this.presentForm.sendTime // 发送时间
        },
        commonDocumentId: this.presentForm.id, // 公共呈批单数据id
        documentId:
          this.presentForm.contractId ||
          this.presentForm.postId ||
          this.presentForm.receiptId ||
          this.presentForm.requestId, // 呈批单数据id
        documentType: this.value, // 呈批单类型
        operationType: operationType, // 操作类型,1-普通修改,2-驳回后编辑
        // 正文
        documentText: {
          applyId: '', // 呈批单id
          essayType: 1, // 文章类型 1正文 2附件
          fileId: this.textId // 文件id
        },
        // 请示呈批单
        officialRequestApply: {
          documentId: this.documentId, // 公文呈批单id
          officeNumber: '无', // 办文编号
          proposedOpinion: '' // 拟办意见
        }
      };
      const api = this.$fetchApi.updateDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      this.id = this.documentId;
    },
     // 发送接口 - 传阅发送
    async circulateBtn() {
      let operationType = 3;
      // 两个之间相差为一自动审批列表-无审批人节点设置为空
      if (this.currentApproveSort + 1 === this.approveSort) {
        this.automaticApprovalPerson = [];
      } 
      const params = {
        automaticApprovalPerson: this.automaticApprovalPerson, // 自动审批列表-无审批人节点
        commonDocumentId: this.getDocumentId, // 公共呈批单id
        documentArea: this.area, // 公文所属省/市
        documentType: this.value, // 呈批单类型
        handleStatus: this.handleStatus, // 处理状态:0-驳回,1-同意
        memberIdList: this.sendingList, // 发文对象id列表-仅在请示呈批单/发文呈批单/收文呈批单类型需要传参
        operationType: operationType, // 呈批单操作类型:1-拟稿人发送,2-拟文流转处理,3-传阅操作
        // 当前流转节点信息
        currentDocumentNodeReq: {
          approveSort: this.currentApproveSort, // 当前流转节点排序
          circulationCode: this.currentCode, // 当前流转节点编号
          handleMemberId: this.currentHandle, // 当前节点处理人用户id,必填,不可传无
          nodeName: this.currentNode // 当前流转节点名称
        },
        // 下一流转节点信息
        nextDocumentNodeReq: {
          approveSort: this.approveSort,
          circulationCode: this.nodeCode,
          handleMemberId: this.child_data,
          nodeName: this.nodeName
        }
      };
      const api = this.$fetchApi.sendDocumentApply.api;
      const data = await this.$fetchData.fetchPost(params, api, 'json');
      if (data.code == '200') {
        this.$message({
          message: '发送成功',
          type: 'success'
        });
        this.$router.push('/documentText');
      }
    }
    // demo() {
    //   console.log('收文子组件方法测试')
    // }
  },
  created() {
    this.value = this.$route.query.code;
    this.documentId = this.$route.query.id;
    this.operationType = this.$route.query.type;
  },
  mounted() {
    // 意见列表回显
    if (this.fatherDetails.opinionList) {
      this.proposedOpinionList = this.fatherDetails.opinionList.proposedOpinion || [] // 拟办意见列表
      this.bureauLeaderOpinionList = this.fatherDetails.opinionList.directorOpinion || [] // 局领导批示列表
      this.officeOpinionList = this.fatherDetails.opinionList.officeOpinion || [] // 处室意见列表
    }
  },
  watch: {
    currentCode: {
      handler(val) {
        if (val === 'DRAFT_MAN') {
          this.isDrafter = false;
        } else {
          this.isDrafter = true;
        }
        if (val === 'QS_FGJLD' || val === 'QS_CWFJZ' || val === 'QS_JZ') {
          this.isBureauLeaderOpinion = false;
        }
        if (val === 'QS_BCSYJ' || val === 'QS_XGCSYJ' || val === 'QS_BGSZR') {
          this.isOfficeOpinion = false;
        }
        if (val === 'QS_HGR' || val === 'DRAFT_MAN') {
          this.isSuggestions = false;
        }
        if (val === 'QS_BGSA' || val === 'QS_BGSB' || val === 'QS_BGSC' || val === 'QS_BGSD') {
          this.isSuggestions = false;
          this.isOfficeOpinion = false;
        }
      },
      immediate: true
    },
    fatherDetails: {
      handler(val) {
        // 意见列表回显
        if (val.opinionList) {
          this.proposedOpinionList = val.opinionList.proposedOpinion || [] // 拟办意见列表
          this.bureauLeaderOpinionList = val.opinionList.directorOpinion || [] // 局领导批示列表
          this.officeOpinionList = val.opinionList.officeOpinion || [] // 处室意见列表
        }
      }
    }
  }
};
</script>
<style lang="less" scoped>
.title {
  margin: 10px 0;
  display: flex;
  font-size: 22px;
  color: red;
  justify-content: space-around;
}
.table {
  font-size: 16px;
  color: red;
  margin: 10px 0;
  width: 758px;
  min-height: 200px;
  border: 1px solid red;
  .rows {
    border-bottom: 1px solid red;
  }
  .row {
    display: flex;
    .opinion {
      border-right: 1px solid red;
      border-bottom: 1px solid red;
      width: 375px;
      // height: 375px;
      p {
        margin-left: 10px;
      }
      /deep/.el-textarea__inner {
        border: none;
        height: 100%;
        resize: none;
        width: 99%;
        margin-left: 1px;
        overflow:hidden
      }
    }
    .opinions {
      border-bottom: 1px solid red;
      width: 382px;
      // height: 375px;
      p {
        margin-left: 10px;
      }
      /deep/.el-textarea__inner {
        border: none;
        height: 100%;
        resize: none;
        width: 99%;
        margin-left: 1px;
        overflow:hidden
      }
    }
    .opinionss {
      border-right: 1px solid red;
      border-bottom: 1px solid red;
      width: 375px;
      // height: 375px;
      p {
        margin-left: 10px;
      }
      /deep/.el-textarea__inner {
        border: none;
        height: 100%;
        resize: none;
        width: 99%;
        margin-left: 1px;
        overflow:hidden
      }
    }
    .del {
      border-bottom: none;
    }
    .metre {
      border-right: 1px solid red;
      border-bottom: 1px solid red;
      width: 81px;
      height: 55px;
      text-align: center;
      line-height: 55px;
    }
    .feed {
      width: 50px;
      height: 35px;
      line-height: 20px;
      padding: 10px 15px;
    }
  }
  .input {
    border-right: 1px solid red;
    border-bottom: 1px solid red;
    width: 130px;
    overflow: hidden;
    /deep/.el-input__inner {
      width: 99%;
      height: 55px;
      border: none;
      font-size: 16px;
    }
    /deep/.el-date-editor--date {
      width: 99%;
    }
    /deep/.el-input__prefix,
    /deep/.el-input__suffix-inner {
      display: none;
    }
    /deep/.el-input__inner {
      padding: 0 15px;
      text-align: center;
    }
    /deep/.el-textarea__inner {
      border: none;
      height: 55px;
      // padding-top: 18px;
      resize: none;
      text-align: center;
    }
  }
  .middle {
    width: 150px;
  }
  .finally {
    width: 235px;
    border-right: none;
  }
  .copies {
    width: 85px;
    border-right: none;
  }
  .official {
    width: 679px;
    max-height: 110px;
    overflow: hidden;
    border-right: none;
  }
}
.textList {
  color: #757575;
  margin: 5px;
  font-size: 14px;
}
/deep/ .el-textarea.is-disabled .el-textarea__inner,
/deep/ .el-input.is-disabled .el-input__inner {
  color: #757575;
}

.echo_box {
  display: table;
  background-color: #f5f7fa;
  height: 100%;
  width: 100%;
  color: #757575;
  font-size: 14px;
}

.echo_text {
  display: table-cell;
  vertical-align:middle;
  text-align:center;
}
</style>