<template>
  <div>
    <div v-if="rightLists.length !== 0 || isTrue">
      <p class="title">{{currentCode == 'FINISH' ? '传阅对象' : '发文对象'}}</p>
      <div class="contents">
        <div class="staff">
          <p v-for="(item, i) in rightLists" :key="i">{{item.name}}</p>
        </div>
        <p class="skip" @click="dialogVisibleBtn">{{currentCode == 'FINISH' ? '选择传阅对象' : '选择发文对象'}} ></p>
      </div>
    </div>
    <div v-if="nodeName === '相关处室意见' && type !== 'proposed'">
      <p class="title">相关处室（单位）处理人</p>
      <div class="contents">
        <div class="staff">
          <el-cascader
            class="cascader"
            v-model="approverName"
            @change="getApprover"
            :options="fromDatas"
            placeholder="请输入审批人"
            filterable
            :props="{ label: 'name', value: 'id' }"
            :show-all-levels="false"
          ></el-cascader>
        </div>
      </div>
    </div>
    <el-dialog
      title="请选择"
      :visible.sync="dialogVisible"
      width="56%"
      :before-close="handleClose"
    >
      <div class="box">
        <tree-transfer
          ref="wl-tree-transfer"
          filter
          high-light
          default-transfer
          :mode="mode"
          :title="title"
          :to_data="toData"
          :from_data="fromData"
          :filterNode="filterNode"
          :defaultProps="defaultProps"
          :defaultCheckedKeys="defaultCheckedKeys"
          :defaultExpandedKeys="[2,3]"
          @right-check-change="rightCheckChange"
          @left-check-change="leftCheckChange"
          @addBtn="add"
          height="340px"
          node_key="id"
        ></tree-transfer>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisibles()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import treeTransfer from './transfer-extend';
// 业务协同   办公管理
export default {
  props: ['nodeName', 'nodeCode', 'currentCode'],

  name: 'App',
  data() {
    return {
      dialogVisible: false,
      mode: 'transfer', // transfer addressList
      defaultProps: { label: 'name', children: 'children' },
      fromData: [], // 穿梭框 - 源数据 - 树形
      toData: [], // 穿梭框 - 目标数据 - 树形
      toArray: [],
      defaultCheckedKeys: [], // 左侧默认选中数据
      rightList: [],
      rightLists: [],
      value: '',
      fromDatas: [], // 设置相关处事意见审批人
      approverName: '',
      approverId: '',
      isTrue: false,
      type: ''
    };
  },
  created() {
    this.type = this.$route.query.type;
  },
  mounted() {
    setTimeout(() => {
      this.defaultCheckedKeys = [1];
      this.getDispatchMemberList();
    }, 500);
   
  },
  watch: {
    currentCode(text) {
      if (text === 'FW_FWR' || text === 'QS_FWR') {
        this.isTrue = true;
      } else {
        this.isTrue = false;
      }
    }
  },
  methods: {
    dialogVisibleBtn() {
      this.dialogVisible = true;
    },
    // 获取相关处事意见审批人
    getApprover(value) {
      console.log(value);
      this.approverId = value[2];
      this.$emit('children-approverId', this.approverId);
    },
    // 弹框右下角确认按钮
    async dialogVisibles() {
      // console.log(this.mode);
      // console.log(this.fromData);
      // console.log(this.toArray);
      // console.log(this.defaultProps);
      if (this.rightList.length !== 0 || this.currentCode === 'FW_FWR' || this.currentCode === 'QS_FWR') {
        this.dialogVisible = false;
        await this.$emit('children-event', this.rightList);
        if (this.currentCode == 'FINISH') {
          this.$emit('circulateBtn');
        }
      } else {
        this.$message('请选择至少一个对象');
      }
     
      // await this.$message('发送成功');
      // await this.$router.push('/officialDocument/awaitDocumentText');
    },
    // 相关处室意见审批人
    async getDispatchMemberList() {
      const api = this.$fetchApi.getDispatchMemberList.api;
      const data = await this.$fetchData.fetchPost({}, api, 'json');
      if (data.code === '200') {
        if (data.result) {
          this.fromData = data.result;
          // console.log(this.nodeName)
          var json_datas = JSON.parse(JSON.stringify(data.result));
          // 去掉最后一层的children
          json_datas.forEach(item => {
            if (item.children.length > 0) {
              item.children.forEach(item1 => {
                if (item1.children.length > 0) {
                  item1.children.forEach(item2 => {
                    delete item2.children;
                  });
                }
              });
            }
          });
          this.fromDatas = json_datas;
        }
      }
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done();
        })
        .catch(_ => {});
    },
    // 自定义筛选函数
    filterNode(value, data, where) {
      console.log(value, data, where);
      if (!value) return true;
      return data[this.defaultProps.label].indexOf(value) !== -1;
    },
    // 添加按钮
    add(fromData, toData, obj) {
      // 树形穿梭框模式transfer时，返回参数为左侧树移动后数据、右侧树移动后数据、移动的{keys,nodes,halfKeys,halfNodes}对象
      // 通讯录模式addressList时，返回参数为右侧收件人列表、右侧抄送人列表、右侧密送人列表
      // console.log('fromData:', fromData);
      // console.log(toData);
      // console.log('obj:', obj);
      // console.log(obj);
      // debugger
    },
    // 左侧源数据选中事件
    leftCheckChange(nodeObj, treeObj, checkAll) {
      // console.log('左侧源数据选中事件');
      // console.log(nodeObj);
      // console.log(treeObj);
      // console.log(checkAll);
    },
    // 右侧目标数据选中事件
    rightCheckChange(nodeObj, treeObj, checkAll) {
      // console.log('右侧目标数据选中事件');
      console.log(nodeObj);
      console.log(treeObj.checkedNodes);
      console.log(checkAll);
      this.rightLists = treeObj.checkedNodes.filter(
        item => item.children.length === 0
      );
      console.log(this.rightLists);
      this.rightList = []
      this.rightLists.forEach(item => this.rightList.push(item.id));
      var demo = this.rightList;
      function unique(demo) {
        return Array.from(new Set(demo));
      }
      this.rightList = unique(demo);
      console.log(this.rightList);
    }
  },
  computed: {
    // eslint-disable-next-line
    title() {
      if (this.mode === 'transfer') {
        return ['请选择', '收信人'];
      }
    }
  },
  components: { treeTransfer }
};
</script>
<style lang="less" scoped>
.box {
  // width: 600px;
  margin: 0 auto;
  text-align: left;
}

.btn {
  border: 1px solid #ebeef5;
  padding: 5px 10px;
  background-color: #f5f7fa;
  outline: none;
}
.title {
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: rgba(25, 31, 46, 1);
  line-height: 24px;
}
.contents {
  position: relative;
  width: 364px;
  height: 140px;
  background: rgba(255, 255, 255, 1);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.1);
  margin: 12px 0 25px 0;
  overflow: hidden;
  .staff {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(150, 158, 180, 1);
    line-height: 24px;
    padding-left: 22px;
    
    .left {
      margin-left: 47px;
    }

    .cascader {
      margin-top: 30px;
    }
  }
  .skip {
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: rgba(75, 81, 98, 1);
    line-height: 24px;
    position: absolute;
    right: 23px;
    bottom: 10px;
  }
}
</style>
